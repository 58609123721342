/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getCompanyOwner = /* GraphQL */ `query GetCompanyOwner($id: ID!) {
  getCompanyOwner(id: $id) {
    id
    email
    subscriptionType
    role
    permissions
    companies {
      items {
        id
        name
        type
        logo
        description
        subscriptionType
        companyOwnerId
        createdAt
        updatedAt
        companyOwnerCompaniesId
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCompanyOwnerQueryVariables,
  APITypes.GetCompanyOwnerQuery
>;
export const listCompanyOwners = /* GraphQL */ `query ListCompanyOwners(
  $filter: ModelCompanyOwnerFilterInput
  $limit: Int
  $nextToken: String
) {
  listCompanyOwners(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      email
      subscriptionType
      role
      permissions
      companies {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCompanyOwnersQueryVariables,
  APITypes.ListCompanyOwnersQuery
>;
export const companyOwnersById = /* GraphQL */ `query CompanyOwnersById(
  $id: String!
  $sortDirection: ModelSortDirection
  $filter: ModelCompanyOwnerFilterInput
  $limit: Int
  $nextToken: String
) {
  companyOwnersById(
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      email
      subscriptionType
      role
      permissions
      companies {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CompanyOwnersByIdQueryVariables,
  APITypes.CompanyOwnersByIdQuery
>;
export const getCompany = /* GraphQL */ `query GetCompany($id: ID!) {
  getCompany(id: $id) {
    id
    name
    users {
      items {
        id
        name
        email
        domain
        role
        permissions
        discrepancyNotification
        discrepancyNotificationTypes
        cognitoId
        companyId
        createdAt
        updatedAt
        companyUsersId
        owner
        __typename
      }
      nextToken
      __typename
    }
    type
    logo
    description
    subscriptionType
    companyOwnerId
    createdAt
    updatedAt
    companyOwnerCompaniesId
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCompanyQueryVariables,
  APITypes.GetCompanyQuery
>;
export const listCompanies = /* GraphQL */ `query ListCompanies(
  $filter: ModelCompanyFilterInput
  $limit: Int
  $nextToken: String
) {
  listCompanies(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      users {
        nextToken
        __typename
      }
      type
      logo
      description
      subscriptionType
      companyOwnerId
      createdAt
      updatedAt
      companyOwnerCompaniesId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCompaniesQueryVariables,
  APITypes.ListCompaniesQuery
>;
export const companiesByCompanyOwnerId = /* GraphQL */ `query CompaniesByCompanyOwnerId(
  $companyOwnerId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelCompanyFilterInput
  $limit: Int
  $nextToken: String
) {
  companiesByCompanyOwnerId(
    companyOwnerId: $companyOwnerId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      users {
        nextToken
        __typename
      }
      type
      logo
      description
      subscriptionType
      companyOwnerId
      createdAt
      updatedAt
      companyOwnerCompaniesId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CompaniesByCompanyOwnerIdQueryVariables,
  APITypes.CompaniesByCompanyOwnerIdQuery
>;
export const getUser = /* GraphQL */ `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    name
    email
    domain
    role
    permissions
    discrepancyNotification
    discrepancyNotificationTypes
    cognitoId
    companyId
    createdAt
    updatedAt
    companyUsersId
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      email
      domain
      role
      permissions
      discrepancyNotification
      discrepancyNotificationTypes
      cognitoId
      companyId
      createdAt
      updatedAt
      companyUsersId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const usersByCognitoId = /* GraphQL */ `query UsersByCognitoId(
  $cognitoId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  usersByCognitoId(
    cognitoId: $cognitoId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      email
      domain
      role
      permissions
      discrepancyNotification
      discrepancyNotificationTypes
      cognitoId
      companyId
      createdAt
      updatedAt
      companyUsersId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UsersByCognitoIdQueryVariables,
  APITypes.UsersByCognitoIdQuery
>;
export const usersByCompanyId = /* GraphQL */ `query UsersByCompanyId(
  $companyId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  usersByCompanyId(
    companyId: $companyId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      email
      domain
      role
      permissions
      discrepancyNotification
      discrepancyNotificationTypes
      cognitoId
      companyId
      createdAt
      updatedAt
      companyUsersId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UsersByCompanyIdQueryVariables,
  APITypes.UsersByCompanyIdQuery
>;
export const getForm = /* GraphQL */ `query GetForm($id: ID!) {
  getForm(id: $id) {
    id
    name
    companyId
    noMachineHours
    formData
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetFormQueryVariables, APITypes.GetFormQuery>;
export const listForms = /* GraphQL */ `query ListForms(
  $filter: ModelFormFilterInput
  $limit: Int
  $nextToken: String
) {
  listForms(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      companyId
      noMachineHours
      formData
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListFormsQueryVariables, APITypes.ListFormsQuery>;
export const formsByCompanyId = /* GraphQL */ `query FormsByCompanyId(
  $companyId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelFormFilterInput
  $limit: Int
  $nextToken: String
) {
  formsByCompanyId(
    companyId: $companyId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      companyId
      noMachineHours
      formData
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FormsByCompanyIdQueryVariables,
  APITypes.FormsByCompanyIdQuery
>;
export const getEquipment = /* GraphQL */ `query GetEquipment($id: ID!) {
  getEquipment(id: $id) {
    id
    name
    companyId
    formId
    equipment {
      id
      name
      machineHours
      lastServiceHours
      __typename
    }
    machineHours
    lastServiceHours
    image
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetEquipmentQueryVariables,
  APITypes.GetEquipmentQuery
>;
export const listEquipment = /* GraphQL */ `query ListEquipment(
  $filter: ModelEquipmentFilterInput
  $limit: Int
  $nextToken: String
) {
  listEquipment(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      companyId
      formId
      equipment {
        id
        name
        machineHours
        lastServiceHours
        __typename
      }
      machineHours
      lastServiceHours
      image
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEquipmentQueryVariables,
  APITypes.ListEquipmentQuery
>;
export const equipmentByCompanyId = /* GraphQL */ `query EquipmentByCompanyId(
  $companyId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelEquipmentFilterInput
  $limit: Int
  $nextToken: String
) {
  equipmentByCompanyId(
    companyId: $companyId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      companyId
      formId
      equipment {
        id
        name
        machineHours
        lastServiceHours
        __typename
      }
      machineHours
      lastServiceHours
      image
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.EquipmentByCompanyIdQueryVariables,
  APITypes.EquipmentByCompanyIdQuery
>;
export const equipmentByFormId = /* GraphQL */ `query EquipmentByFormId(
  $formId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelEquipmentFilterInput
  $limit: Int
  $nextToken: String
) {
  equipmentByFormId(
    formId: $formId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      companyId
      formId
      equipment {
        id
        name
        machineHours
        lastServiceHours
        __typename
      }
      machineHours
      lastServiceHours
      image
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.EquipmentByFormIdQueryVariables,
  APITypes.EquipmentByFormIdQuery
>;
export const getDiscrepancy = /* GraphQL */ `query GetDiscrepancy($id: ID!) {
  getDiscrepancy(id: $id) {
    id
    companyId
    equipmentId
    unitId
    questionIndex
    equipmentName
    initialStatus
    status
    log
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDiscrepancyQueryVariables,
  APITypes.GetDiscrepancyQuery
>;
export const listDiscrepancies = /* GraphQL */ `query ListDiscrepancies(
  $filter: ModelDiscrepancyFilterInput
  $limit: Int
  $nextToken: String
) {
  listDiscrepancies(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      companyId
      equipmentId
      unitId
      questionIndex
      equipmentName
      initialStatus
      status
      log
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDiscrepanciesQueryVariables,
  APITypes.ListDiscrepanciesQuery
>;
export const discrepanciesByCompanyIdAndUpdatedAt = /* GraphQL */ `query DiscrepanciesByCompanyIdAndUpdatedAt(
  $companyId: String!
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelDiscrepancyFilterInput
  $limit: Int
  $nextToken: String
) {
  discrepanciesByCompanyIdAndUpdatedAt(
    companyId: $companyId
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      companyId
      equipmentId
      unitId
      questionIndex
      equipmentName
      initialStatus
      status
      log
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.DiscrepanciesByCompanyIdAndUpdatedAtQueryVariables,
  APITypes.DiscrepanciesByCompanyIdAndUpdatedAtQuery
>;
export const discrepanciesByEquipmentIdAndUpdatedAt = /* GraphQL */ `query DiscrepanciesByEquipmentIdAndUpdatedAt(
  $equipmentId: String!
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelDiscrepancyFilterInput
  $limit: Int
  $nextToken: String
) {
  discrepanciesByEquipmentIdAndUpdatedAt(
    equipmentId: $equipmentId
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      companyId
      equipmentId
      unitId
      questionIndex
      equipmentName
      initialStatus
      status
      log
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.DiscrepanciesByEquipmentIdAndUpdatedAtQueryVariables,
  APITypes.DiscrepanciesByEquipmentIdAndUpdatedAtQuery
>;
export const discrepanciesByUnitIdAndUpdatedAt = /* GraphQL */ `query DiscrepanciesByUnitIdAndUpdatedAt(
  $unitId: String!
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelDiscrepancyFilterInput
  $limit: Int
  $nextToken: String
) {
  discrepanciesByUnitIdAndUpdatedAt(
    unitId: $unitId
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      companyId
      equipmentId
      unitId
      questionIndex
      equipmentName
      initialStatus
      status
      log
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.DiscrepanciesByUnitIdAndUpdatedAtQueryVariables,
  APITypes.DiscrepanciesByUnitIdAndUpdatedAtQuery
>;
export const getArchivedDiscrepancy = /* GraphQL */ `query GetArchivedDiscrepancy($id: ID!) {
  getArchivedDiscrepancy(id: $id) {
    id
    companyId
    equipmentId
    unitId
    questionIndex
    equipmentName
    initialStatus
    status
    log
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetArchivedDiscrepancyQueryVariables,
  APITypes.GetArchivedDiscrepancyQuery
>;
export const listArchivedDiscrepancies = /* GraphQL */ `query ListArchivedDiscrepancies(
  $filter: ModelArchivedDiscrepancyFilterInput
  $limit: Int
  $nextToken: String
) {
  listArchivedDiscrepancies(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      companyId
      equipmentId
      unitId
      questionIndex
      equipmentName
      initialStatus
      status
      log
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListArchivedDiscrepanciesQueryVariables,
  APITypes.ListArchivedDiscrepanciesQuery
>;
export const archivedDiscrepanciesByCompanyIdAndUpdatedAt = /* GraphQL */ `query ArchivedDiscrepanciesByCompanyIdAndUpdatedAt(
  $companyId: String!
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelArchivedDiscrepancyFilterInput
  $limit: Int
  $nextToken: String
) {
  archivedDiscrepanciesByCompanyIdAndUpdatedAt(
    companyId: $companyId
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      companyId
      equipmentId
      unitId
      questionIndex
      equipmentName
      initialStatus
      status
      log
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ArchivedDiscrepanciesByCompanyIdAndUpdatedAtQueryVariables,
  APITypes.ArchivedDiscrepanciesByCompanyIdAndUpdatedAtQuery
>;
export const archivedDiscrepanciesByEquipmentIdAndUpdatedAt = /* GraphQL */ `query ArchivedDiscrepanciesByEquipmentIdAndUpdatedAt(
  $equipmentId: String!
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelArchivedDiscrepancyFilterInput
  $limit: Int
  $nextToken: String
) {
  archivedDiscrepanciesByEquipmentIdAndUpdatedAt(
    equipmentId: $equipmentId
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      companyId
      equipmentId
      unitId
      questionIndex
      equipmentName
      initialStatus
      status
      log
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ArchivedDiscrepanciesByEquipmentIdAndUpdatedAtQueryVariables,
  APITypes.ArchivedDiscrepanciesByEquipmentIdAndUpdatedAtQuery
>;
export const archivedDiscrepanciesByUnitIdAndUpdatedAt = /* GraphQL */ `query ArchivedDiscrepanciesByUnitIdAndUpdatedAt(
  $unitId: String!
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelArchivedDiscrepancyFilterInput
  $limit: Int
  $nextToken: String
) {
  archivedDiscrepanciesByUnitIdAndUpdatedAt(
    unitId: $unitId
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      companyId
      equipmentId
      unitId
      questionIndex
      equipmentName
      initialStatus
      status
      log
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ArchivedDiscrepanciesByUnitIdAndUpdatedAtQueryVariables,
  APITypes.ArchivedDiscrepanciesByUnitIdAndUpdatedAtQuery
>;
export const getReport = /* GraphQL */ `query GetReport($id: ID!) {
  getReport(id: $id) {
    id
    companyId
    equipmentId
    unitId
    unitName
    equipmentName
    userId
    name
    userData
    userLocation
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetReportQueryVariables, APITypes.GetReportQuery>;
export const listReports = /* GraphQL */ `query ListReports(
  $filter: ModelReportFilterInput
  $limit: Int
  $nextToken: String
) {
  listReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      companyId
      equipmentId
      unitId
      unitName
      equipmentName
      userId
      name
      userData
      userLocation
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReportsQueryVariables,
  APITypes.ListReportsQuery
>;
export const reportsByCompanyId = /* GraphQL */ `query ReportsByCompanyId(
  $companyId: String!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelReportFilterInput
  $limit: Int
  $nextToken: String
) {
  reportsByCompanyId(
    companyId: $companyId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      companyId
      equipmentId
      unitId
      unitName
      equipmentName
      userId
      name
      userData
      userLocation
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ReportsByCompanyIdQueryVariables,
  APITypes.ReportsByCompanyIdQuery
>;
export const reportsByEquipmentId = /* GraphQL */ `query ReportsByEquipmentId(
  $equipmentId: String!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelReportFilterInput
  $limit: Int
  $nextToken: String
) {
  reportsByEquipmentId(
    equipmentId: $equipmentId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      companyId
      equipmentId
      unitId
      unitName
      equipmentName
      userId
      name
      userData
      userLocation
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ReportsByEquipmentIdQueryVariables,
  APITypes.ReportsByEquipmentIdQuery
>;
export const reportsByUnitId = /* GraphQL */ `query ReportsByUnitId(
  $unitId: String!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelReportFilterInput
  $limit: Int
  $nextToken: String
) {
  reportsByUnitId(
    unitId: $unitId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      companyId
      equipmentId
      unitId
      unitName
      equipmentName
      userId
      name
      userData
      userLocation
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ReportsByUnitIdQueryVariables,
  APITypes.ReportsByUnitIdQuery
>;
export const getErrors = /* GraphQL */ `query GetErrors($id: ID!) {
  getErrors(id: $id) {
    id
    errors
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetErrorsQueryVariables, APITypes.GetErrorsQuery>;
export const listErrors = /* GraphQL */ `query ListErrors(
  $filter: ModelErrorsFilterInput
  $limit: Int
  $nextToken: String
) {
  listErrors(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      errors
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListErrorsQueryVariables,
  APITypes.ListErrorsQuery
>;
