
import { Box, Link } from "@cloudscape-design/components";
import { AuthEventData } from '@aws-amplify/ui';

export const SignInFooter = (props: { toForgotPassword: (data?: AuthEventData) => void, forgotPasswordText: React.ReactNode; }) => {
  const { toForgotPassword, forgotPasswordText } = props;
  return (
    <Box textAlign="center" margin="xl">
      <div className="auth-reset-pw_link">
        <Link onFollow={toForgotPassword}>{forgotPasswordText}</Link>
      </div>
    </Box>
  );
};
