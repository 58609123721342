import { useEffect } from "react";
import { Image, View, Flex } from '@aws-amplify/ui-react';
// import { signUp, SignUpInput } from 'aws-amplify/auth';
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import { useNavigate, useLocation } from "react-router";
import { SignUpHeader } from "../components/Auth/SignUpHeader/SignUpHeader";
import { generateClient } from "aws-amplify/api";
import { CreateCompanyOwnerInput } from "../API";
// import { DEFAULT_ADMIN_PERMISSIONS, NETWORK_DOMAIN, ROLES } from "../utils/constants";
import { createCompanyOwner } from "../graphql/mutations";
import { Hub } from "aws-amplify/utils";
import { ALL_USER_PERMISSIONS, SUPER_ADMIN_USER_PERMISSIONS } from "../utils/constants";
const client = generateClient({ authMode: "userPool" });


export const FreeDemoLogin = () => {
    // const { route } = useAuthenticator((context) => [context.route]);
    const { route } = useAuthenticator((context) => [
        context.route,
    ]);
    // const { toForgotPassword } = useAuthenticator();
    // const location = useLocation();
    const navigate = useNavigate();
    // const from = location.state?.from?.pathname ? `${location.state?.from?.pathname}${location.state?.from?.search}` : "/register/new-company";
    // useEffect(() => {
    //     if (route === "authenticated") {
    //         navigate(from, { replace: true });
    //     }
    // }, [route, navigate, from]);

    useEffect(() => {
        // Listen for sign-in/sign-up events
        const listener = Hub.listen('auth', async ({ payload: { event, data } }) => {
            console.log('data', data)
            console.log('event', event)
            if (event === 'signedIn') {
                // User successfully signed in
                const email = data.username
                const cognitoId = data.userId; // Cognito User's ID (sub)
                console.log('Cognito ID:', cognitoId);
                console.log('email', email)

                navigate("/admin", { replace: true });
                // Save the Cognito ID to DynamoDB
                await saveToDynamoDB(cognitoId, email);

                // Redirect after successful sign-up
                // if (route === "authenticated") {
                // }; // Or wherever you want to redirect
            }
        });

        // Cleanup listener when component is unmounted
        return () => listener();
    }, [navigate]);

    const saveToDynamoDB = async (cognitoId: string, email: string) => {
        const input: CreateCompanyOwnerInput = {
            id: cognitoId,
            email: email,
            subscriptionType: "free-demo",
            role: "super-admin",
            permissions: JSON.stringify(
                SUPER_ADMIN_USER_PERMISSIONS
            ),
        }

        try {
            console.log('input', input)

            const response = await client.graphql({
                query: createCompanyOwner,
                variables: { input },
            });

            console.log('DynamoDB Entry Created:', response);
        } catch (error) {
            console.error('Error saving to DynamoDB:', error);
        }
    };

    // const handleSignUpComplete = async (data: any, e: React.FormEvent) => {
    //     e.preventDefault();
    //     const { attributes, userSub } = data;  // `userSub` is the Cognito ID (sub)

    //     console.log('Cognito userSub:', userSub);
    //     console.log('User attributes:', attributes);
    //     try {
    //         const input: CreateCompanyOwnerInput = {
    //             email: attributes.email.trim().toLowerCase(),
    //             name: attributes.name.trim(),
    //             subscriptionType: "free-demo"
    //         }

    //         console.log('input', input)
    //         await client.graphql({
    //             query: createCompanyOwner,
    //             variables: { input },
    //         });
    //     } catch (error) {
    //         console.error('Error creating user in DynamoDB:', error);
    //     }
    // };

    // const services = {
    //     async handleSignUp(input: SignUpInput) {
    //         // custom username and email
    //         const { options } = input;
    //         const customName = options?.userAttributes?.name?.toLowerCase();
    //         const customEmail = options?.userAttributes?.email?.toLowerCase();
    //         return signUp({
    //             name: customName,
    //             options: {
    //                 ...input.options,
    //                 userAttributes: {
    //                     ...input.options?.userAttributes,
    //                     email: customEmail,
    //                 },
    //             },
    //         });
    //     },
    // }
    const components = {
        SignUp: {
            Header() {
                return <SignUpHeader />
            },
        },
    };

    const formFields = {
        signUp: {
            name: {
                placeholder: "Enter Your Name Here",
                isRequired: true,
                label: "Name:",
            },
        },
    }

    return (
        <Flex
            direction="row"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            wrap="wrap"
            gap="1rem"
            width="100%"
            minHeight="100vh"
            position="relative"
            top="medium"
        >
            <View
                height="30rem"
                position="relative"
            >
                <Image
                    height="100%" width="100%" objectFit="cover"
                    src="/edited-loader.jpg"
                    alt="image of loader operations"
                    style={{
                        position: 'relative',
                        top: 0,
                        left: 0,
                        zIndex: 100
                    }}
                />
                <Image
                    height="150px"
                    objectFit="cover"
                    src="/Equipmentinspectionforms.com-transparent-logo.png"
                    alt="image of Quarry operations"
                    style={{
                        position: 'absolute',
                        top: 5,
                        left: 5,
                        zIndex: 200
                    }}
                />
            </View>
            <View>
                <Authenticator
                    key="signUp"
                    initialState={"signUp"}
                    components={components}
                    formFields={formFields}
                    loginMechanisms={["email"]}
                ></Authenticator>
            </View>
        </Flex>
    )
};
