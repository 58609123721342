import { Routes, Route } from "react-router-dom";
import { IndexedDB } from "react-indexed-db-hook";
import { RequireAuth } from "./components/Auth/RequireAuth/RequireAuth";
import { Admin } from "./pages/Admin";
// import { Company } from "./pages/Company";
import { UsersContent } from "./components/UsersContent";
import { Dashboard } from "./pages/Dashboard";
import { InspectionOptions } from "./pages/InspectionOptions";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Login } from "./pages/Login";
import { NotFound } from "./pages/NotFound";
import { QRCodes } from "./pages/QRCodes";
import { Register } from "./pages/Register";
import { ReportSent } from "./pages/ReportSent";
import { PERMISSION_VALUES, ROLES } from "./utils/constants";
import { SplashPage } from "./pages/SplashPage";
import { SuperAdminLogin } from "./pages/SuperAdminLogin";
import { FreeDemoLogin } from "./pages/FreeDemoLogin";
import { Company } from "./pages/Company";
import { UpgradeSubscription } from "./components/UpgradeSubscription";
// import { NewCompanyRegistration } from "./pages/NewCompanyRegistration";

export const AppRoutes = () => {
  const { user } = useAuthenticator((context) => {
    return [context.user];
  });
  // console.log("user", user)
  return (
    <IndexedDB
      name="EquipmentInspection"
      version={1}
      objectStoresMeta={[
        {
          store: "media",
          storeConfig: { keyPath: "id", autoIncrement: true },
          storeSchema: [
            { name: "name", keypath: "name", options: { unique: false } },
            { name: "file", keypath: "file", options: { unique: false } },
            {
              name: "question",
              keypath: "question",
              options: { unique: false },
            },
            { name: "formId", keypath: "formId", options: { unique: false } },
          ],
        },
      ]}
    >
      <Routes>
        <Route path="/" element={user ? <Dashboard page="dashboard" /> : <SplashPage />} />
        <Route
          path="/dashboard"
          element={user ? <Dashboard page="dashboard" /> : <SplashPage />}
        />
        <Route
          path="/admin"
          element={
            <RequireAuth role={ROLES.SUPER_ADMIN}>
              <Admin />
            </RequireAuth>
          }
        />
        {/* <Route
          path="/register/new-company"
          element={
            <RequireAuth role={ROLES.SUPER_ADMIN}>
              <NewCompanyRegistration />
            </RequireAuth>
          }
        /> */}
        <Route
          path="/company"
          element={
            <RequireAuth role={ROLES.SUPER_ADMIN}>
              <Company role={ROLES.SUPER_ADMIN} parent="/admin" selfPath="/company" />
            </RequireAuth>
          }
        />
        <Route
          path="/company/users"
          element={
            <RequireAuth role={ROLES.SUPER_ADMIN} permission={PERMISSION_VALUES.MANAGE_USERS}>
              <UsersContent role={ROLES.SUPER_ADMIN} parent="/admin" selfPath="/company/users" />
            </RequireAuth>
          }
        />
        <Route
          path="/company/forms"
          element={
            <RequireAuth role={ROLES.SUPER_ADMIN} permission={PERMISSION_VALUES.MANAGE_FORMS}>
              <Dashboard page="forms" />
            </RequireAuth>
          }
        />
        <Route
          path="/company/equipment"
          element={
            <RequireAuth role={ROLES.SUPER_ADMIN} permission={PERMISSION_VALUES.MANAGE_EQUIPMENT}>
              <Dashboard page="equipment" />
            </RequireAuth>
          }
        />
        <Route
          path="/company/issues"
          element={
            <RequireAuth role={ROLES.SUPER_ADMIN} permission={PERMISSION_VALUES.VIEW_DISCREPANCIES}>
              <Dashboard page="issues" />
            </RequireAuth>
          }
        />
        <Route
          path="/company/reports"
          element={
            <RequireAuth role={ROLES.SUPER_ADMIN} permission={PERMISSION_VALUES.VIEW_REPORTS}>
              <Dashboard page="reports" />
            </RequireAuth>
          }
        />
        <Route
          path="/users"
          element={
            <RequireAuth role={ROLES.ADMIN} permission={PERMISSION_VALUES.MANAGE_USERS}>
              <Dashboard page="users" />
            </RequireAuth>
          }
        />
        <Route
          path="/forms"
          element={
            <RequireAuth role={ROLES.ADMIN} permission={PERMISSION_VALUES.MANAGE_FORMS}>
              <Dashboard page="forms" />
            </RequireAuth>
          }
        />
        <Route
          path="/equipment"
          element={
            <RequireAuth role={ROLES.ADMIN} permission={PERMISSION_VALUES.MANAGE_EQUIPMENT}>
              <Dashboard page="equipment" />
            </RequireAuth>
          }
        />
        <Route
          path="/issues"
          element={
            <RequireAuth role={ROLES.ADMIN} permission={PERMISSION_VALUES.VIEW_DISCREPANCIES}>
              <Dashboard page="issues" />
            </RequireAuth>
          }
        />
        <Route
          path="/qr-codes"
          element={
            <RequireAuth role={ROLES.SUPER_ADMIN || ROLES.ADMIN} permission={PERMISSION_VALUES.MANAGE_EQUIPMENT}>
              <QRCodes />
            </RequireAuth>
          }
        />
        <Route
          path="/reports"
          element={
            <RequireAuth role={ROLES.ADMIN} permission={PERMISSION_VALUES.VIEW_REPORTS}>
              <Dashboard page="reports" />
            </RequireAuth>
          }
        />
        <Route
          path="/report/:id"
          element={
            <RequireAuth permission={PERMISSION_VALUES.VIEW_REPORTS}>
              <Dashboard page="report" />
            </RequireAuth>
          }
        />
        <Route
          path="/lastreport/:id/:equipmentId"
          element={
            <RequireAuth permission={PERMISSION_VALUES.VIEW_REPORTS}>
              <Dashboard page="report" noBreadCrumbs noNavBar />
            </RequireAuth>
          }
        />
        <Route
          path="/inspect/:id"
          element={
            <RequireAuth permission={PERMISSION_VALUES.INSPECT}>
              <InspectionOptions />
            </RequireAuth>
          }
        />
        <Route
          path="/report-sent"
          element={
            <RequireAuth permission={PERMISSION_VALUES.INSPECT}>
              <ReportSent />
            </RequireAuth>
          }
        />
        <Route path="/register/:userIdFromParams" element={<Register />} />
        {/* <Route path="/register/new-company" element={<NewCompanyRegistration />} /> */}

        <Route path="/login" element={<Login logIn={true} />} />
        <Route path="/register/admin" element={<SuperAdminLogin />} />
        <Route path="/register/free-demo" element={<FreeDemoLogin />} />

        <Route path="/register/subscription" element={<UpgradeSubscription />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </IndexedDB>
  );
};
