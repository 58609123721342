import { useEffect, useState } from "react";
import { generateClient } from "aws-amplify/api";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import {
  Box,
  Button,
  Checkbox,
  FileUpload,
  FormField,
  Header,
  Input,
  Link,
  Modal,
  Select,
  SpaceBetween,
  Table,
} from "@cloudscape-design/components";

import * as Sentry from "@sentry/browser";

import { resizeFile } from "../utils/helperFunctions";
import {
  DEFAULT_REPORT_LABELS,
  INPUT_MODES,
  LOCAL_STORAGE_KEYS,
  MAX_NUMBER_EQUIPMENT_FREE_DEMO,
  RESET_EQUIPMENT_UNIT,
} from "../utils/constants";

import { useUserContext } from "../contexts/UserContext";

import {
  createEquipment as createEquipmentMutation,
  deleteEquipment as deleteEquipmentMutation,
  updateEquipment as updateEquipmentMutation,
} from "../graphql/mutations";
import {
  equipmentByCompanyId as equipmentByCompanyIdQuery,
  formsByCompanyId as formsByCompanyIdQuery,
  listCompanies as listCompaniesQuery,
} from "../graphql/queries";
import { Equipment, Unit, Form as FormType, CreateEquipmentInput, UpdateEquipmentInput, UnitInput } from "../API";
import { tSelectLabelValue } from "../types";
import { useCompanyContext } from "../contexts/CompanyContext";

const client = generateClient({ authMode: "userPool" });



export const EquipmentContent = () => {
  const [editImage, setEditImage] = useState("");
  const [equipmentId, setEquipmentId] = useState<string | null>(null);
  const [equipmentList, setEquipmentList] = useState<Equipment[] | []>([]);
  const [equipmentName, setEquipmentName] = useState("");
  const [equipmentPhoto, setEquipmentPhoto] = useState<Blob[] | []>([]);
  const [equipmentUnits, setEquipmentUnits] = useState<Unit[] | UnitInput[] | []>([]);
  const [machineHours, setMachineHours] = useState<string | null>(null);
  const [lastServiceHours, setLastServiceHours] = useState<string | null>(null);
  const [formsList, setFormsList] = useState<FormType[] | []>([]);
  const [isAddingEquipment, setIsAddingEquipment] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoadingEquipmentList, setIsLoadingEquipmentList] = useState(false);
  const [isLoadingFormsList, setIsLoadingFormsList] = useState(false);
  const [selectedForm, setSelectedForm] = useState<tSelectLabelValue | null>(null);
  const [selectedItems, setSelectedItems] = useState<Equipment[]>([]);
  const [freeDemoCapacityMet, setFreeDemoCapacityMet] = useState<boolean>(false)
  const [isFreeDemo, setIsFreeDemo] = useState<boolean>(false)
  const [selectToDeleteEquipment, setSelectToDeleteEquipment] = useState<Equipment | null>(null);
  const [
    shouldUseQRCodesForClassification,
    setShouldUseQRCodesForClassification,
  ] = useState(
    !!localStorage.getItem(LOCAL_STORAGE_KEYS.QR_CLASSIFICATION_DEFAULT)
  );
  const [formUnit, setFormUnit] = useState(RESET_EQUIPMENT_UNIT);
  const { company } = useUserContext();
  const { company: companyContext } = useCompanyContext();

  const companyId = company?.id as string || companyContext?.id as string
  const navigate = useNavigate();

  const getEquipmentList = async () => {
    if (!company && !companyContext) {
      return;
    }
    setIsLoadingEquipmentList(true);
    try {
      const result = await client.graphql({
        query: equipmentByCompanyIdQuery,
        variables: {
          companyId: companyId
        },
      });

      const companyResult = await client.graphql({
        query: listCompaniesQuery,
      })

      const currentCompany = companyResult.data.listCompanies.items.find((company) => company.id === companyId)

      setEquipmentList(
        result.data.equipmentByCompanyId.items.sort((a, b) =>
          a.updatedAt < b.updatedAt ? 1 : -1
        )
      );

      if (currentCompany?.subscriptionType === "free-demo") {
        setIsFreeDemo(true)
      } else {
        setIsFreeDemo(false)
      }

      if (result.data.equipmentByCompanyId.items.length >= MAX_NUMBER_EQUIPMENT_FREE_DEMO) {
        setFreeDemoCapacityMet(true)
      } else {
        setFreeDemoCapacityMet(false)
      }
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    } finally {
      setIsLoadingEquipmentList(false);
    }
  };

  const getFormsList = async () => {
    if (!company && !companyContext) {
      return;
    }
    setIsLoadingFormsList(true);
    try {
      const result = await client.graphql({
        query: formsByCompanyIdQuery,
        variables: {
          companyId: companyId,
        },
      });
      setFormsList(result.data.formsByCompanyId.items);
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    } finally {
      setIsLoadingFormsList(false);
    }
  };

  useEffect(() => {
    getEquipmentList();
    getFormsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const equipmentSubmitHandler = async (e: { preventDefault: () => void }) => {
    if ((!company && !companyContext) || !selectedForm?.value) {
      return;
    }
    e.preventDefault();
    setIsLoadingEquipmentList(true);
    try {
      const resizedImage =
        equipmentPhoto.length > 0 ? (await resizeFile(equipmentPhoto[0])) as string : "";
      const imageToSave =
        editImage && !equipmentPhoto[0] ? editImage : resizedImage;
      if (!equipmentId) {
        const input: CreateEquipmentInput = {
          name: equipmentName,
          companyId: companyId,
          formId: selectedForm?.value,
          image: imageToSave,
          equipment: equipmentUnits.map((unit) => {
            const newUnit: UnitInput = { ...unit };
            return newUnit;
          }),
          machineHours:
            machineHours && !equipmentUnits
              ? parseFloat(machineHours)
              : undefined,
          lastServiceHours:
            lastServiceHours && !equipmentUnits
              ? parseFloat(lastServiceHours)
              : undefined,
        };
        await client.graphql({
          query: createEquipmentMutation,
          variables: {
            input,
          },
        });
      } else {
        const input: UpdateEquipmentInput = {
          id: equipmentId,
          name: equipmentName,
          companyId: companyId,
          formId: selectedForm?.value,
          image: imageToSave,
          equipment: equipmentUnits.map((unit) => {
            const newUnit: UnitInput = { ...unit };
            return newUnit;
          }),
          machineHours:
            machineHours && !equipmentUnits
              ? parseFloat(machineHours)
              : undefined,
          lastServiceHours:
            lastServiceHours && !equipmentUnits
              ? parseFloat(lastServiceHours)
              : undefined,
        };
        await client.graphql({
          query: updateEquipmentMutation,
          variables: {
            input,
          },
        });
      }
      getEquipmentList();
      setEquipmentName("");
      setEquipmentId(null);
      setEquipmentUnits([]);
      setSelectedForm(null);
      setEquipmentPhoto([]);
      setEditImage("");
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    } finally {
      setIsLoadingEquipmentList(false);
      setIsEditing(false);
      setIsAddingEquipment(false);
    }
  };

  const equipmentForm = () => {
    return (
      <Box margin={{ top: "l" }}>
        <SpaceBetween direction="vertical" size="l">
          <FormField
            label={`Equipment ${shouldUseQRCodesForClassification ? "Classification" : "Name"
              }`}
            stretch
          >
            <Input
              autoComplete={false}
              onChange={({ detail }) => {
                setEquipmentName(detail.value);
              }}
              value={equipmentName}
            />
          </FormField>
          <FormField label="Inspection Form" stretch>
            <Select
              statusType={isLoadingFormsList ? "loading" : undefined}
              selectedOption={selectedForm}
              onChange={({ detail }) => {
                setSelectedForm(detail.selectedOption as tSelectLabelValue);
              }}
              options={formsList.map((form) => {
                return {
                  label: form.name,
                  value: form.id,
                };
              })}
            />
          </FormField>
          <FormField label="Use QR Codes for Equipment Classification" stretch>
            <Checkbox
              description={
                <>
                  <Box variant="p">
                    Leave unchecked if each unit should have its own unique QR
                    code.
                  </Box>
                  <Box variant="p">
                    Check this box if you want to use the same QR code for each
                    unit within a classification. (e.g 'Loader #1' and 'Loader
                    #2' would use the same QR code for all 'Loaders'. Users will
                    need to select which unit they want to inspect when they
                    scan the QR code.)
                  </Box>
                </>
              }
              onChange={({ detail }) => {
                detail.checked
                  ? localStorage.setItem(
                    LOCAL_STORAGE_KEYS.QR_CLASSIFICATION_DEFAULT,
                    `${detail.checked}`
                  )
                  : localStorage.removeItem(
                    LOCAL_STORAGE_KEYS.QR_CLASSIFICATION_DEFAULT
                  );
                setShouldUseQRCodesForClassification(detail.checked);
              }}
              checked={
                shouldUseQRCodesForClassification || equipmentUnits?.length > 0
              }
            />
          </FormField>
          {!shouldUseQRCodesForClassification && (
            <>
              <FormField label={DEFAULT_REPORT_LABELS.machineHours}>
                <Input
                  type="number"
                  inputMode={INPUT_MODES.NUMBER}
                  value={machineHours || ''}
                  onChange={({ detail }) => setMachineHours(detail.value)}
                />
              </FormField>
              <FormField label={DEFAULT_REPORT_LABELS.lastServiceHours}>
                <Input
                  type="number"
                  inputMode={INPUT_MODES.NUMBER}
                  value={lastServiceHours || ''}
                  onChange={({ detail }) => setLastServiceHours(detail.value)}
                />
              </FormField>
            </>
          )}
          {editImage && (
            <SpaceBetween direction="horizontal" size="xl">
              <img
                src={editImage}
                alt={equipmentName}
                style={{ maxWidth: "100px" }}
              />
              <Button
                variant="icon"
                iconName="remove"
                onClick={() => setEditImage("")}
              />
            </SpaceBetween>
          )}
          <Box margin={{ top: "l" }}>
            <FormField
              label={
                <>
                  Photo - <i>optional</i>
                </>
              }
              description="Image of equipment"
            >
              <FileUpload
                accept="image/jpeg, image/jpg, image/png"
                onChange={({ detail }) => setEquipmentPhoto(detail.value)}
                value={equipmentPhoto as readonly File[]}
                i18nStrings={{
                  uploadButtonText: (e) => (e ? "Choose files" : "Choose file"),
                  dropzoneText: (e) =>
                    e ? "Drop files to upload" : "Drop file to upload",
                  removeFileAriaLabel: (e) => `Remove file ${e + 1}`,
                  limitShowFewer: "Show fewer files",
                  limitShowMore: "Show more files",
                  errorIconAriaLabel: "Error",
                }}
                showFileLastModified
                showFileSize
                showFileThumbnail
                tokenLimit={1}
                constraintText=".jpg, .jpeg, or .png file"
              />
            </FormField>
          </Box>
          {(shouldUseQRCodesForClassification ||
            equipmentUnits?.length > 0) && (
              <Box margin={{ bottom: "xxl", top: "xxl" }}>
                <SpaceBetween direction="vertical" size="xl">
                  <Table
                    header={
                      <Header counter={`(${equipmentUnits.length})`}>
                        {equipmentName ? equipmentName : "Equipment List"}
                      </Header>
                    }
                    variant="embedded"
                    ariaLabels={{
                      activateEditLabel: (column, item) =>
                        `Edit ${item.name} ${column.header}`,
                      cancelEditLabel: (column) =>
                        `Cancel editing ${column.header}`,
                      submitEditLabel: (column) =>
                        `Submit editing ${column.header}`,
                      tableLabel: equipmentName
                        ? `List Of ${equipmentName}`
                        : "Equipment List",
                    }}
                    columnDefinitions={[
                      {
                        id: "name",
                        header: "Name",
                        cell: (item) => item.name,
                        editConfig: {
                          ariaLabel: "Name",
                          editIconAriaLabel: "editable",
                          errorIconAriaLabel: "Name error",
                          editingCell: (item, { currentValue, setValue }) => {
                            return (
                              <Input
                                type="text"
                                autoFocus
                                value={currentValue ?? item.name}
                                onChange={({ detail }) => {
                                  setValue(detail.value);
                                }}
                              />
                            );
                          },
                        },
                      },
                      {
                        id: "machineHours",
                        header: DEFAULT_REPORT_LABELS.machineHours,
                        cell: (item) => item.machineHours,
                        editConfig: {
                          ariaLabel: DEFAULT_REPORT_LABELS.machineHours,
                          editIconAriaLabel: "editable",
                          errorIconAriaLabel: `${DEFAULT_REPORT_LABELS.machineHours} error`,
                          editingCell: (item, { currentValue, setValue }) => {
                            return (
                              <Input
                                type="number"
                                inputMode={INPUT_MODES.NUMBER}
                                value={currentValue ?? item.machineHours}
                                onChange={({ detail }) => {
                                  setValue(detail.value);
                                }}
                              />
                            );
                          },
                        },
                      },
                      {
                        id: "lastServiceHours",
                        header: DEFAULT_REPORT_LABELS.lastServiceHours,
                        cell: (item) => item.lastServiceHours,
                        editConfig: {
                          ariaLabel: DEFAULT_REPORT_LABELS.lastServiceHours,
                          editIconAriaLabel: "editable",
                          errorIconAriaLabel: `${DEFAULT_REPORT_LABELS.lastServiceHours} error`,
                          editingCell: (item, { currentValue, setValue }) => {
                            return (
                              <Input
                                type="number"
                                inputMode={INPUT_MODES.NUMBER}
                                value={currentValue ?? item.lastServiceHours}
                                onChange={({ detail }) => setValue(detail.value)}
                              />
                            );
                          },
                        },
                      },
                      {
                        id: "timeSinceLastService",
                        header: DEFAULT_REPORT_LABELS.timeSinceLastService,
                        cell: (item) =>
                          Number(item.machineHours) -
                          Number(item.lastServiceHours),
                      },
                      {
                        id: "actions",
                        header: "Delete",
                        cell: (item) => {
                          return (
                            <Button
                              formAction="none"
                              variant="icon"
                              iconName="remove"
                              onClick={() => {
                                const newUnitsList = equipmentUnits.filter(
                                  (unit) => unit.id !== item.id
                                );
                                setEquipmentUnits(newUnitsList);
                              }}
                            />
                          );
                        },
                      },
                    ]}
                    items={equipmentUnits || []}
                    submitEdit={(item, column, newValue) => {
                      const idToEdit = equipmentUnits.findIndex(
                        (unit) => unit.id === item.id
                      );
                      equipmentUnits[idToEdit][column.id as keyof UnitInput] = newValue as never;
                      setEquipmentUnits(equipmentUnits);
                    }
                    }
                    empty={`No ${equipmentName ? equipmentName : "units"
                      }.  Add a unit below.`}
                  />
                  <FormField label="Unit Name/ID">
                    <Input
                      type="text"
                      value={formUnit.name || ''}
                      onChange={({ detail }) =>
                        setFormUnit({
                          ...formUnit,
                          name: detail.value,
                        })
                      }
                    />
                  </FormField>
                  <FormField label={DEFAULT_REPORT_LABELS.machineHours}>
                    <Input
                      type="number"
                      inputMode={INPUT_MODES.NUMBER}
                      value={`${formUnit.machineHours}`}
                      onChange={({ detail }) =>
                        setFormUnit({
                          ...formUnit,
                          machineHours: Number(detail.value),
                        })
                      }
                    />
                  </FormField>
                  <FormField label={DEFAULT_REPORT_LABELS.lastServiceHours}>
                    <Input
                      type="number"
                      inputMode={INPUT_MODES.NUMBER}
                      value={`${formUnit.lastServiceHours}`}
                      onChange={({ detail }) =>
                        setFormUnit({
                          ...formUnit,
                          lastServiceHours: Number(detail.value),
                        })
                      }
                    />
                  </FormField>
                  <Button
                    formAction="none"
                    onClick={(e) => {
                      e.preventDefault();
                      const newFormUnits: UnitInput[] = equipmentUnits
                        ? [...equipmentUnits]
                        : [];
                      newFormUnits.push({
                        id: uuidv4(),
                        name: formUnit.name,
                        machineHours: formUnit.machineHours,
                        lastServiceHours: formUnit.lastServiceHours,
                      });
                      setEquipmentUnits(newFormUnits);
                      setFormUnit(RESET_EQUIPMENT_UNIT);
                    }}
                  >
                    Add Unit
                  </Button>
                </SpaceBetween>
              </Box>
            )}
        </SpaceBetween>
      </Box>
    );
  };

  const createInspectionForm = () => {
    return (
      <Link
        onFollow={(e) => {
          e.preventDefault();
          navigate("/forms");
        }}
      >
        Create an inspection form for your equipment
      </Link>
    );
  };

  return (
    <>
      <Table
        variant="embedded"
        onSelectionChange={({ detail }) =>
          setSelectedItems(detail.selectedItems)
        }
        selectedItems={selectedItems}
        ariaLabels={{
          selectionGroupLabel: "Items selection",
          allItemsSelectionLabel: ({ selectedItems }) =>
            `${selectedItems.length} ${selectedItems.length === 1 ? "item" : "items"
            } selected`,
          itemSelectionLabel: (_, item) => item.name,
        }}
        selectionType="multi"
        loading={isLoadingEquipmentList}
        loadingText="Loading Equipment..."
        columnDisplay={[
          {
            id: "name",
            visible: true,
          },
          {
            id: "form",
            visible: true,
          },
          {
            id: "image",
            visible: true,
          },
          {
            id: "units",
            visible: equipmentList.some((item) => {
              const units = item.equipment;
              return units ? units.length > 0 : false;
            }),
          },
          {
            id: "edit",
            visible: true,
          },
          {
            id: "remove",
            visible: true,
          },
        ]}
        columnDefinitions={[
          {
            id: "name",
            header: "Name",
            cell: (item) => item.name,
          },
          {
            id: "form",
            header: "Inspection Form",
            cell: (item) => {
              const form = formsList.find((form) => form.id === item.formId);
              return form?.name || "--";
            },
          },
          {
            id: "image",
            header: "Image",
            cell: (item) => {
              return (
                <>
                  {item.image ? (
                    <img
                      src={item.image}
                      alt={item.name}
                      style={{ maxWidth: "100px" }}
                    />
                  ) : (
                    <></>
                  )}
                </>
              );
            },
          },
          {
            id: "units",
            header: "Units",
            cell: (item) => {
              const units = item.equipment;
              return units?.length ? units?.length : "--";
            },
          },
          {
            id: "edit",
            header: "Edit",
            cell: (item) => {
              return (
                <Button
                  formAction="none"
                  variant="icon"
                  iconName="edit"
                  onClick={() => {
                    const form = formsList.find(
                      (form) => form.id === item.formId
                    );
                    setIsEditing(true);
                    setEquipmentName(item.name);
                    setEquipmentId(item.id);
                    setEditImage(item.image || '');
                    setEquipmentUnits(item.equipment as Unit[]);
                    setMachineHours(`${item.machineHours}` || '');
                    setShouldUseQRCodesForClassification(
                      !!item?.equipment?.length
                    );
                    setLastServiceHours(`${item.lastServiceHours}` || '');
                    setSelectedForm({
                      label: form?.name || '',
                      value: form?.id || '',
                    });
                  }}
                />
              );
            },
          },
          {
            id: "remove",
            header: "Delete",
            cell: (item) => {
              return (
                <Button
                  formAction="none"
                  variant="icon"
                  iconName="remove"
                  onClick={() => setSelectToDeleteEquipment(item)}
                />
              );
            },
          },
        ]}
        items={equipmentList}
        empty={
          <Box textAlign="center" color="inherit">
            <b>No equipment</b>
          </Box>
        }
        header={
          <Header
            counter={`(${equipmentList.length})`}
            actions={
              <SpaceBetween direction="horizontal" size="l">
                <Button onClick={() => setIsAddingEquipment(true)} disabled={isFreeDemo && freeDemoCapacityMet}>
                  Add Equipment
                </Button>
                <Button
                  formAction="none"
                  disabled={!selectedItems.length}
                  onClick={() => {
                    sessionStorage.setItem(
                      "equipmentList",
                      JSON.stringify(selectedItems)
                    );
                    navigate("/qr-codes");
                  }}
                >
                  Generate QR Codes
                </Button>
              </SpaceBetween>
            }
          >
            {isFreeDemo && freeDemoCapacityMet && (
              <p style={{ color: 'red', marginTop: '10px' }}>
                Upgrade Subscription to add More Users
              </p>
            )}
            {`Equipment ${shouldUseQRCodesForClassification ? "Classifications" : "List"
              }`}
          </Header>
        }
      />
      <Modal
        size="max"
        onDismiss={() => {
          setEquipmentName("");
          setEquipmentId(null);
          setEquipmentUnits([]);
          setSelectedForm(null);
          setEquipmentPhoto([]);
          setEditImage("");
          setIsEditing(false);
          setIsAddingEquipment(false);
        }}
        visible={isAddingEquipment || isEditing}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                key="cancel"
                variant="normal"
                formAction="none"
                onClick={() => {
                  setEquipmentName("");
                  setEquipmentId(null);
                  setEquipmentUnits([]);
                  setSelectedForm(null);
                  setEquipmentPhoto([]);
                  setEditImage("");
                  setIsEditing(false);
                  setIsAddingEquipment(false);
                  setMachineHours(null);
                  setLastServiceHours(null);
                }}
              >
                Cancel
              </Button>
              <Button
                key="submit"
                onClick={equipmentSubmitHandler}
                loading={isLoadingEquipmentList}
                variant="primary"
                disabled={
                  !equipmentName ||
                  !selectedForm ||
                  (shouldUseQRCodesForClassification &&
                    equipmentUnits?.length === 0)
                }
              >
                Submit
              </Button>
            </SpaceBetween>
          </Box>
        }
      >
        <>
          <Header variant="h1">
            Create Equipment
          </Header>
          {formsList.length > 0 ? equipmentForm() : createInspectionForm()}
        </>
      </Modal>
      <Modal
        onDismiss={() => setSelectToDeleteEquipment(null)}
        visible={!!selectToDeleteEquipment?.id}
        header={`Remove ${selectToDeleteEquipment?.name}`}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                key="no"
                variant="link"
                onClick={() => setSelectToDeleteEquipment(null)}
              >
                No
              </Button>
              <Button
                key="yes"
                loading={isDeleting}
                loadingText="Removing Equipment..."
                variant="primary"
                onClick={async () => {
                  if (!selectToDeleteEquipment?.id) {
                    return;
                  }
                  try {
                    await client.graphql({
                      query: deleteEquipmentMutation,
                      variables: {
                        input: {
                          id: selectToDeleteEquipment?.id,
                        },
                      },
                    });
                    getEquipmentList();
                    setSelectToDeleteEquipment(null);
                  } catch (error) {
                    console.log(error);
                    Sentry.captureException(error);
                  } finally {
                    setIsDeleting(false);
                  }
                }}
              >
                Yes
              </Button>
            </SpaceBetween>
          </Box>
        }
      >
        You are about to delete {`"${selectToDeleteEquipment?.name}"`}. Are you
        sure?
      </Modal>
    </>
  );
};
