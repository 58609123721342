import {
    Card,
    Image,
    Heading,
    Flex,
    Text,
} from '@aws-amplify/ui-react';
import { useUserContext } from "../contexts/UserContext";

/**
 *React component where we can update company specific bio and colors and images/icons and then use them elsewhere in the application - hopefully one single place to simplify additions of new companies. 
 * */
export const CompanyInfo = () => {
    const { company } = useUserContext();

    return (
        <Card variation='outlined'>
            <Flex direction="column" justifyContent="center" alignContent="center" alignItems="center">
                <Flex
                    direction="column"
                    alignItems="center"
                >
                    <Image
                        src={company?.logo ? company.logo : "./site-admin-img.png"}
                        alt="company logo"
                        maxHeight="300px"
                        id="page-top"
                    />
                </Flex>
                <Flex
                    direction="column"
                    justifyContent="flex-start"
                >

                    <Heading level={4}>
                        {company?.name}
                    </Heading>

                    <Text as="span" fontStyle="italic">
                        {company?.type ? company.type : ""}
                    </Text>
                    <Text as='span' fontSize="1.2em">
                        {company?.description ? company.description : ""}
                    </Text>
                </Flex>
            </Flex>
        </Card >

    )

}