export const DBConfig = {
    name: "EquipmentInspection",
    version: 1,
    objectStoresMeta: [
        {
            store: "media",
            storeConfig: { keyPath: "id", autoIncrement: true },
            storeSchema: [
                { name: "name", keypath: "name", options: { unique: false } },
                { name: "file", keypath: "file", options: { unique: false } },
                { name: "question", keypath: "question", options: { unique: false } },
                { name: "formId", keypath: "formId", options: { unique: false } },
            ],
        },
    ],
};
