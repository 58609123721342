import Resizer from "react-image-file-resizer";
import { SUPER_ADMIN_USER_IDS, FREE_DEMO_USER_IDS, PERMISSION_VALUES } from "./constants";
import { User } from "../API";


export const getBaseRoute = (path: string): string => path.split("/")[1];

export const capitalizeWord = (word: string): string =>
  word.charAt(0).toUpperCase() + word.slice(1);

export const isNumeric = (n: string | number | undefined): boolean => {
  if (typeof n === "undefined") return false;
  return !isNaN(parseFloat(`${n}`)) && isFinite(Number(n));
};

export const resizeFile = (file: Blob) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

export const resizeLogo = (file: Blob) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      500,
      500,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

export const base64ToBlob = (base64: string | null, mimeType: string): Blob | null => {
  if (!base64 || base64.split(",")[0] !== "data:image/jpeg;base64") {
    return null; // Return null if base64 is invalid
  }
  const byteString = atob(base64.split(',')[1]);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const intArray = new Uint8Array(arrayBuffer);

  for (let i = 0; i < byteString.length; i++) {
    intArray[i] = byteString.charCodeAt(i);
  }

  return new Blob([intArray], { type: mimeType });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getNumberOfPrompts = (formDataArray: any[]) => {
  let numberOfPrompts = 0;
  if (!formDataArray?.length) return 0;
  formDataArray.forEach((formData) => {
    if (formData.prompt) {
      numberOfPrompts++;
    }
    if (formData.prompts) {
      numberOfPrompts += formData.prompts.length;
    }
  });
  return numberOfPrompts;
};

export const getTypeFromFileName = (fileName: string): string | null => {
  if (!fileName) return null;
  const path = fileName.toLowerCase();
  switch (true) {
    case path.includes(".png") ||
      path.includes(".jpeg") ||
      path.includes(".jpg"):
    default:
      return "image";
    case path.includes(".mov") || path.includes(".mp4"):
      return "video";
    case path.includes(".mp3") || path.includes(".wav"):
      return "audio";
  }
};

export const userHasExplicitPermission = (user: User | null, permission: PERMISSION_VALUES | undefined): boolean => {
  if (!permission) {
    return false;
  }
  return !!user?.permissions?.includes(permission);
}