import React from "react";
import ReactDOM from "react-dom/client";
import { Amplify } from "aws-amplify";
import { Authenticator, ThemeProvider } from "@aws-amplify/ui-react";
import { App } from "./App.js";

import { DBConfig } from "./DBConfig.ts";
import { initDB } from "react-indexed-db-hook";

// check if an element with a key of "EIFClearDB" is over a day old
// if so, delete the database
const clearDB = localStorage.getItem("EIFClearDB");
const now = new Date();
if (clearDB) {
  const clearDBDate = new Date(clearDB);
  if (now.getTime() - clearDBDate.getTime() > 86400000) {
    localStorage.setItem("EIFClearDB", `${now.getTime()}`);
    indexedDB.deleteDatabase(DBConfig.name);
  }
} else {
  localStorage.setItem("EIFClearDB", `${now.getTime()}`);
  indexedDB.deleteDatabase(DBConfig.name);
}

initDB(DBConfig);

import config from "./aws-exports.js";

import "@cloudscape-design/global-styles/index.css";
import "@aws-amplify/ui-react/styles.css";
import "./index.css";

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://5cb9ea23d9e245efe15ec59265627216@o4507421376970752.ingest.us.sentry.io/4507421379461120",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "equipmentinspectionforms.com",
    /^https:\/\/amazonaws\.com\/graphql/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled: process.env.NODE_ENV !== 'development',
});

Amplify.configure(config);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Authenticator.Provider>
      <ThemeProvider>
        <App />
      </ThemeProvider>
    </Authenticator.Provider>
  </React.StrictMode>
);
