import { Icon } from "@cloudscape-design/components";
import { ITEM_STATUS_CODES } from "../utils/constants";
import { DisplayStatusIconProps } from "../types";

export const DisplayStatusIcon = ({ children, size }: DisplayStatusIconProps) => {
  switch (children) {
    case ITEM_STATUS_CODES.inop:
      return <Icon name="status-negative" size={size} variant="error" />;
    case ITEM_STATUS_CODES.warn:
      return <Icon url="/status-warning-svgrepo-com.svg" size={size} variant="normal" />;
    case ITEM_STATUS_CODES.ok:
      return <Icon name="status-positive" size={size} variant="success" />;
    case ITEM_STATUS_CODES.na:
      return <Icon url="/not-applicable-svgrepo-full-black.svg" size={size} variant="subtle" />;
    default:
      return <>{children}</>;
  }
};

