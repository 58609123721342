// RequireAuth.js
import { useEffect, useState } from "react";
import { useLocation, Navigate } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { userHasExplicitPermission } from "../../../utils/helperFunctions";
import { NotAuthorized } from "../NotAuthorized";
import { ROLES } from "../../../utils/constants";
import { useUserContext } from "../../../contexts/UserContext";
import { iRequireAuthProps } from "../../../types";
import { LoadingSpinner } from "../../LoadingSpinner";
import {

  listCompanies,
  // listCompanyOwners as listCompanyOwnersQuery
} from "../../../graphql/queries";
import { generateClient } from "aws-amplify/api";
// import { CompanyOwner } from "../../../API";
const client = generateClient({ authMode: "apiKey" });



export function RequireAuth({ children, role, permission }: iRequireAuthProps) {
  const [userPermission, setUserPermission] = useState<boolean>(false);
  // const [companyOwnerFullAccessData, setCompanyOwnerFullAccessData] = useState<CompanyOwner[]>([]);
  // const [companyOwnerFreeDemoData, setCompanyOwnerFreeDemoData] = useState<CompanyOwner[]>([]);
  const [isAdmin, setIsAdmin] = useState<boolean>(false)
  // const [isFreeAdmin, setIsFreeAdmin] = useState<boolean>(false)

  const [hasEvaluatedPermissions, setHasEvaluatedPermissions] = useState<boolean>(false);
  const location = useLocation();
  const { route, user } = useAuthenticator((context) => [
    context.route,
    context.user,
  ]);
  const { companyUser } = useUserContext();


  const getCompany = async () => {
    try {
      const response = await client.graphql({
        query: listCompanies
      })
      const companyList = response.data.listCompanies.items
      setIsAdmin(companyList.findIndex((admin) => admin.companyOwnerId === user.userId) !== -1)
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    // console.log('roles', role)
    role === "super-admin" ? setIsAdmin(true) : setIsAdmin(false)
    if (user) {
      getCompany()
    }
  }, [])

  // const getCompanyOwners = async () => {
  //   try {
  //     const response = await client.graphql({
  //       query: listCompanyOwnersQuery,
  //     });
  //     const allCompanyOwnersData =
  //       response?.data?.listCompanyOwners?.items

  //     const fullAccessOwners = allCompanyOwnersData.filter(
  //       (owner) => owner.subscriptionType === "paid-full-access"
  //     );
  //     setCompanyOwnerFullAccessData(fullAccessOwners as CompanyOwner[])
  //     const freeDemoOwners = allCompanyOwnersData.filter(
  //       (owner) => owner.subscriptionType === "free-demo"
  //     );
  //     setCompanyOwnerFreeDemoData(freeDemoOwners as CompanyOwner[])

  //     // return fullAccessOwners as CompanyOwner[]
  //     // const isAdmin = companyOwnerFullAccessData.findIndex((admin) => admin.id === user.userId) !== -1
  //     // const isFreeDemoAdmin = companyOwnerFreeDemoData.findIndex((admin) => admin.id === user.userId) !== -1

  //     // setIsFullAdmin(isAdmin)
  //     // setIsFreeAdmin(isFreeDemoAdmin)
  //   }
  //   catch (error) {
  //     console.error(error)
  //   }
  // }

  // useEffect(() => {
  //   getCompanyOwners()
  // }, [])

  useEffect(() => {
    if (permission && companyUser) {
      if (userHasExplicitPermission(companyUser, permission)) {
        setUserPermission(true);
        setHasEvaluatedPermissions(true)
        return
      } else {
        setUserPermission(false);
        setHasEvaluatedPermissions(true)
        return
      }
    }
    if (role) {
      switch (role) {
        // case `${ROLES.ADMIN} ${ROLES.SUPER_ADMIN}`:
        //   if ((user && isFreeAdmin) || isFullAdmin) {
        //     setUserPermission(true);
        //     setHasEvaluatedPermissions(true)
        //   }
        //   break;
        case ROLES.SUPER_ADMIN:
          // if ((user && isFreeAdmin) || isFullAdmin) {
          if (user && isAdmin) {
            setUserPermission(true);
            setHasEvaluatedPermissions(true)
          }
          break;
        case ROLES.ADMIN:
          if (companyUser?.role === ROLES.ADMIN) {
            setUserPermission(true);
            setHasEvaluatedPermissions(true)
          }
          break;
        default:
          setUserPermission(false);
          setHasEvaluatedPermissions(true)
      }
    }
  }, [companyUser, permission, role, user, isAdmin])

  if (route === "setup" && !user) {
    return <Navigate to="/login" state={{ from: location }} />;
  }
  return <LoadingSpinner isLoading={!hasEvaluatedPermissions} content={userPermission ? children : <NotAuthorized />} />
}
