import { useEffect } from "react";
import { Box } from "@cloudscape-design/components";
import { SignInHeader } from "../components/Auth/SignInHeader/SignInHeader";
import { SignInFooter } from "../components/Auth/SignInFooter/SignInFooter";

import { Authenticator, useAuthenticator, View } from "@aws-amplify/ui-react";
import { useNavigate, useLocation } from "react-router";
import { Hub } from "aws-amplify/utils";
import { CreateCompanyOwnerInput } from "../API";
import { generateClient } from "aws-amplify/api";
import { createCompanyOwner } from "../graphql/mutations";
const client = generateClient({ authMode: "userPool" });

export const SuperAdminLogin = () => {
    const { route } = useAuthenticator((context) => [
        context.route,
    ]);
    // const { toForgotPassword } = useAuthenticator();
    const location = useLocation();
    const navigate = useNavigate();
    const { toForgotPassword } = useAuthenticator();
    const from = location.state?.from?.pathname ? `${location.state?.from?.pathname}${location.state?.from?.search}` : "/register/new-company";
    useEffect(() => {
        if (route === "authenticated") {
            navigate(from, { replace: true });
        }
    }, [route, navigate, from]);

    useEffect(() => {
        // Listen for sign-in/sign-up events
        const listener = Hub.listen('auth', async ({ payload: { event, data } }) => {
            console.log('data', data)
            console.log('event', event)
            if (event === 'signedIn') {
                // User successfully signed in
                const email = data.username
                const cognitoId = data.userId; // Cognito User's ID (sub)
                console.log('Cognito ID:', cognitoId);
                console.log('email', email)

                // Save the Cognito ID to DynamoDB
                await saveToDynamoDB(cognitoId, email);

                // Redirect after successful sign-up
                if (route === "authenticated") {
                    navigate("/admin", { replace: true });
                }; // Or wherever you want to redirect
            }
        });

        // Cleanup listener when component is unmounted
        return () => listener();
    }, [navigate]);

    const saveToDynamoDB = async (cognitoId: string, email: string) => {
        const input: CreateCompanyOwnerInput = {
            id: cognitoId,
            email: email,
            subscriptionType: "paid-full-access"
        }

        try {
            console.log('input', input)

            const response = await client.graphql({
                query: createCompanyOwner,
                variables: { input },
            });

            console.log('DynamoDB Entry Created:', response);
        } catch (error) {
            console.error('Error saving to DynamoDB:', error);
        }
    };


    const components = {
        SignIn: {
            Header() {
                return <SignInHeader />;
            },
            Footer() {
                return (
                    <SignInFooter
                        toForgotPassword={toForgotPassword}
                        forgotPasswordText="Forgot Password"
                    />
                );
            },
        },
    };
    const formFields = {
        signUp: {
            name: {
                placeholder: "Enter Your Name Here",
                isRequired: true,
                label: "Name:",
            },
        },
    }
        ;
    return (
        <View className="auth-wrapper centered-wrapper">
            <Box textAlign="center">
                <img
                    className="centered-logo"
                    src="/ei_logo.jpg"
                    alt="logo"
                    width="300"
                />
            </Box>
            <Authenticator
                key={"signUp"}
                initialState={"signUp"}
                components={components}
                formFields={formFields}
                loginMechanisms={["email"]}
            ></Authenticator>
        </View>
    );
};
