import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  BreadcrumbGroup,
  SpaceBetween,
} from "@cloudscape-design/components";
import { useUserContext } from "../contexts/UserContext";
import { LoadingSpinner } from "../components/LoadingSpinner";
import { DashboardContent } from "../components/DashboardContentUsers";
import { FormsContent } from "../components/FormsContent";
import { EquipmentContent } from "../components/EquipmentContent";
import {
  ROLES,
} from "../utils/constants";
import { capitalizeWord } from "../utils/helperFunctions";
import { ReportsContent } from "../components/ReportsContent";
import { InspectionReport } from "../components/InspectionReport";
import { Issues } from "../components/Issues";
import { iDashboardInterface } from "../types";
import { SuperAdminDashboardContent } from "../components/DashboardContentSuperAdmin";
import { UsersContent } from "../components/UsersContent";
import { useCompanyContext } from "../contexts/CompanyContext";

export const Dashboard = ({
  page,
  noBreadCrumbs = false,
  noNavBar = false,
}: iDashboardInterface) => {
  const { companyUser, isLoading, setNoNavBar, company } = useUserContext();
  const { company: companyContext } = useCompanyContext();
  const navigate = useNavigate();

  // console.log('company user', companyUser)
  // console.log('company ', companyContext)

  useEffect(() => {
    setNoNavBar(noNavBar);
  }, [noNavBar, setNoNavBar]);

  const breadcrumbs =
    page === "report"
      ? [
        {
          text: "Reports",
          href: "/reports",
        },
        {
          text: "Report",
          href: "",
        },
      ]
      : [
        {
          text: capitalizeWord(page),
          href: `/${page}`,
        },
      ];


  return (
    <LoadingSpinner
      isLoading={isLoading}
      content={
        <>
          {(!companyUser) ? (
            // <SuperAdminDashboardContent />
            <SpaceBetween direction="vertical" size="l">
              {!noBreadCrumbs && (page !== "dashboard") && (
                <div className="hide-from-printer">
                  <BreadcrumbGroup
                    ariaLabel="breadcrumbs"
                    onFollow={(event) => {
                      event.preventDefault();
                      navigate(event.detail.href);
                    }}
                    items={[
                      {
                        text: "Dashboard",
                        href: "/company",
                      },
                      ...breadcrumbs,
                    ]}
                  />
                </div>
              )}
              {!page ||
                (page === "dashboard" && (
                  <SuperAdminDashboardContent subscriptionType={companyContext?.subscriptionType || ""} />
                ))}
              {page === "forms" && <FormsContent />}
              {page === "equipment" && <EquipmentContent />}
              {page === 'issues' && <Issues />}
              {page === "users" && (<UsersContent parent="/company" />
                // hideBreadCrumbs
                // parent="/dashboard"
                // role={ROLES.INSPECTOR}
                // selfPath="/users"
                // />
              )}
              {page === "reports" && <ReportsContent />}
              {page === "report" && <InspectionReport />}
            </SpaceBetween>
          ) : (
            <SpaceBetween direction="vertical" size="l">
              {companyUser?.role && !noBreadCrumbs && (page !== "dashboard") && (
                <div className="hide-from-printer">
                  <BreadcrumbGroup
                    ariaLabel="breadcrumbs"
                    onFollow={(event) => {
                      event.preventDefault();
                      navigate(event.detail.href);
                    }}
                    items={[
                      {
                        text: "Home",
                        href: "/dashboard",
                      },
                      ...breadcrumbs,
                    ]}
                  />
                </div>
              )}
              {!page ||
                (page === "dashboard" && companyUser && (
                  <DashboardContent companyUser={companyUser} />
                ))}
              {page === "forms" && <FormsContent />}
              {page === "equipment" && <EquipmentContent />}
              {page === 'issues' && <Issues />}
              {page === "users" && (
                <UsersContent
                  hideBreadCrumbs
                  parent="/dashboard"
                  role={ROLES.INSPECTOR}
                  selfPath="/users"
                />
              )}
              {page === "reports" && <ReportsContent />}
              {page === "report" && <InspectionReport />}
            </SpaceBetween>
          )}
        </>
      }
    />
  );
};
