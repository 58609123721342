import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { generateClient } from "aws-amplify/api";
import {
  Box,
  Button,
  Form,
  FormField,
  Input,
  Header,
  Link,
  Modal,
  SpaceBetween,
  Table,
  FileUpload,
  Textarea,
} from "@cloudscape-design/components";
import * as Sentry from "@sentry/browser";
import {
  createCompany as createCompanyMutation,
  deleteCompany as deleteCompanyMutation,
  updateCompany as updateCompanyMutation,
} from "../graphql/mutations";
import {
  listCompanies as listCompaniesQuery,
  listCompanyOwners as listCompanyOwnersQuery
} from "../graphql/queries";
import { useCompanyContext } from "../contexts/CompanyContext";
import { Company, CompanyOwner, CreateCompanyInput, UpdateCompanyInput } from "../API";
import { resizeLogo, base64ToBlob } from "../utils/helperFunctions";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { MAX_NUMBER_COMPANY_FREE_DEMO } from "../utils/constants";

const client = generateClient({ authMode: "userPool" });

export const CompanyAdmin = () => {
  const [companyNameInForm, setCompanyNameInForm] = useState("");
  const [companyLogoInForm, setCompanyLogoInForm] = useState<Blob[] | []>([]);;
  const [companyDescriptionInForm, setCompanyDescriptionInForm] = useState("");
  const [companyTypeInForm, setCompanyTypeInForm] = useState("");
  const [isCreating, setIsCreating] = useState<boolean>(false);

  const [companyData, setCompanyData] = useState<Company[]>([]);
  const [companyDataByOwner, setCompanyDataByOwner] = useState<Company[]>([]);
  const [companyToDelete, setCompanyToDelete] = useState<Company | null>(null);
  const [companiesToEdit, setCompaniesToEdit] = useState<Company[]>([]);
  const [subscriptionType, setSubscriptionType] = useState("");
  const [companyOwnerId, setCompanyOwnerId] = useState("");
  const [companyOwnerData, setCompanyOwnerData] = useState<CompanyOwner[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { setCompany } = useCompanyContext();
  const { user, route } = useAuthenticator((context) => [
    context.user,
    context.route,
  ]);

  const [freeDemoCapacityMet, setFreeDemoCapacityMet] = useState<boolean>(false)
  const [isFreeDemo, setIsFreeDemo] = useState<boolean>(false)
  const [isAddCompanyModelOpen, setIsAddCompanyModelOpen] = useState(false);
  const [isEditCompanyModelOpen, setIsEditCompanyModelOpen] = useState(false);

  const navigate = useNavigate();

  const getCompanyData = async () => {
    setIsLoading(true);
    try {
      const response = await client.graphql({
        query: listCompaniesQuery,
      });
      const allCompaniesData =
        response?.data?.listCompanies?.items.sort((a, b) =>
          a.name > b.name ? 1 : -1
        ) || null;
      setCompanyData(allCompaniesData as Company[]);
      const companyByOwnerData = allCompaniesData.filter((owner) => owner.companyOwnerId === user.userId)
      setCompanyDataByOwner(companyByOwnerData as Company[])
    } catch (error) {
      console.log("error :>> ", error);
      Sentry.captureException(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCompanyOwnerData();
    getCompanyData();
  }, []);

  const getCompanyOwnerData = async () => {
    setIsLoading(true);
    try {
      const response = await client.graphql({
        query: listCompanyOwnersQuery,
      });
      const allCompanyOwnersData =
        response?.data?.listCompanyOwners?.items

      const currentCompanyOwner = allCompanyOwnersData.find((owner) => owner.id === user.userId)

      if (user) {
        setCompanyOwnerId(user.userId)
      }
      if (currentCompanyOwner) {
        setSubscriptionType(currentCompanyOwner.subscriptionType)
        setIsFreeDemo(currentCompanyOwner.subscriptionType === "free-demo")
      }

      if (allCompanyOwnersData.length >= MAX_NUMBER_COMPANY_FREE_DEMO) {
        setFreeDemoCapacityMet(true)
      } else {
        setFreeDemoCapacityMet(false)
      }
      setCompanyOwnerData(allCompanyOwnersData as CompanyOwner[]);
    } catch (error) {
      console.log("error :>> ", error);
      Sentry.captureException(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      getCompanyOwnerData();
    }
  }, []);

  const deleteCompanyHandler = async () => {
    if (companyToDelete) {
      try {
        await client.graphql({
          query: deleteCompanyMutation,
          variables: { input: { id: companyToDelete.id } },
        });
        setCompanyToDelete(null);
        getCompanyData();
      } catch (error) {
        console.log("error :>> ", error);
        Sentry.captureException(error);
      }
    }
  };

  const handleAddCompanyFormSubmit = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    try {
      const ownerId = user.userId
      const resizedLogo =
        companyLogoInForm.length > 0 ? (await resizeLogo(companyLogoInForm[0])) as string : "";

      const input: CreateCompanyInput = {
        name: companyNameInForm,
        type: companyTypeInForm,
        logo: resizedLogo,
        description: companyDescriptionInForm,
        subscriptionType: subscriptionType,
        companyOwnerId: ownerId,
      }

      const response = await client.graphql({
        query: createCompanyMutation,
        variables: { input },
      });
      getCompanyData();
      setCompanyNameInForm("");
      setCompanyTypeInForm('')
      setCompanyDescriptionInForm('')
      setCompanyLogoInForm([])
    } catch (error) {
      console.log("error :>> ", error);
      Sentry.captureException(error);
    } finally {
      setIsAddCompanyModelOpen(false);
    }
  };

  const handleEditFormSubmit = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    try {
      const resizedLogo =
        companyLogoInForm.length > 0 ? (await resizeLogo(companyLogoInForm[0])) as string : companiesToEdit[0].logo;

      setIsCreating(true)
      const input: UpdateCompanyInput = {
        id: companiesToEdit[0].id,
        name: companyNameInForm.trim() ? companyNameInForm.trim() : companiesToEdit[0].name,
        type: companyTypeInForm.trim() ? companyTypeInForm.trim() : companiesToEdit[0].type,
        logo: resizedLogo,
        description: companyDescriptionInForm ? companyDescriptionInForm.trim() : companiesToEdit[0].description,
      }
      await client.graphql({
        query: updateCompanyMutation,
        variables: { input },
      });
      await getCompanyData();
      setCompanyNameInForm("");
      setCompanyTypeInForm('')
      setCompanyDescriptionInForm('')
      setCompanyLogoInForm([])
    } catch (error) {
      console.log("error :>> ", error);
      Sentry.captureException(error);
    } finally {
      setIsEditCompanyModelOpen(false);
      setIsCreating(false)
    }
  };
  return (
    <>
      <SpaceBetween direction="vertical" size="l">
        <Table
          header={
            <Header
              variant="h2"
              actions={
                <Button onClick={() => setIsAddCompanyModelOpen(true)} disabled={isFreeDemo && freeDemoCapacityMet}>
                  Add a Company
                </Button>
              }
            >
              {isFreeDemo && freeDemoCapacityMet && (
                <p style={{ color: 'red', marginTop: '10px' }}>
                  Upgrade Subscription to add Additional Company
                </p>
              )}
            </Header>
          }
          columnDisplay={[
            {
              id: "name",
              visible: true,
            },
            {
              id: "edit",
              visible: true,
            },
            {
              id: "delete",
              visible: true,
            },

          ]}
          columnDefinitions={[
            {
              id: "name",
              header: "Name",
              cell: (item) =>
                <Link
                  onFollow={() => {
                    setCompany(item);
                    navigate("/company");
                    return false;
                  }}
                >
                  {item.name}
                </Link>
            },
            {
              id: "edit",
              header: "Edit",
              cell: (item) =>
                <Button
                  variant="icon"
                  iconName="edit"
                  loading={isCreating}
                  onClick={async () => {
                    const resizedBlob = base64ToBlob(item.logo as string, "image/jpeg")
                    if (resizedBlob) {
                      const resizedFile = new File([resizedBlob], "Company Logo" as string, { type: "image/jepg" })
                      setCompanyLogoInForm([resizedFile])
                    } else setCompanyLogoInForm([])
                    setCompaniesToEdit([item]);
                    setCompanyNameInForm(item.name)
                    setCompanyTypeInForm(item.type ? item.type : "")
                    setCompanyDescriptionInForm(item.description ? item.description : "")
                    setIsEditCompanyModelOpen(true)
                  }}
                />
            },
            {
              id: "delete",
              header: "Delete",
              cell: (item) => (
                <Button
                  variant="icon"
                  iconName="remove"
                  onClick={async () => {
                    setCompanyToDelete(item);
                  }}
                />
              ),
            },
          ]}
          empty={<Box textAlign="center">No Companies</Box>}
          items={companyDataByOwner}
          loading={isLoading}
          loadingText="Loading..."
          trackBy="id"
        // variant="embedded"
        />
      </SpaceBetween>

      {/* add company modal */}
      <Modal
        visible={isAddCompanyModelOpen}
        onDismiss={() => setIsAddCompanyModelOpen(false)}
      >
        <Form
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button onClick={() => setIsAddCompanyModelOpen(false)}>
                Cancel
              </Button>
              <Button
                onClick={handleAddCompanyFormSubmit}
                variant="primary"
                formAction="submit"
                disabled={!companyNameInForm.trim() || !companyTypeInForm.trim() || !companyDescriptionInForm.trim() || !companyLogoInForm}
              >
                Submit
              </Button>
            </SpaceBetween>
          }>
          <SpaceBetween direction="vertical" size="l">
            <SpaceBetween direction="vertical" size="l">
              <FormField label="Company Name">
                <Input
                  value={companyNameInForm}
                  placeholder="e.g. Basin Rock"
                  onChange={({ detail }) => setCompanyNameInForm(detail.value)}
                />
              </FormField>
              <FormField label="Company Type">
                <Input
                  value={companyTypeInForm}
                  placeholder="e.g. Construction"
                  onChange={({ detail }) => setCompanyTypeInForm(detail.value)}
                />
              </FormField>
              <FormField label="Company Logo">
                <FileUpload
                  accept="image/jpeg, image/jpg, image/png"
                  onChange={({ detail }) => setCompanyLogoInForm(detail.value)}
                  value={companyLogoInForm as readonly File[]}
                  i18nStrings={{
                    uploadButtonText: (e) => (e ? "Choose files" : "Choose file"),
                    dropzoneText: (e) =>
                      e ? "Drop files to upload" : "Drop file to upload",
                    removeFileAriaLabel: (e) => `Remove file ${e + 1}`,
                    limitShowFewer: "Show fewer files",
                    limitShowMore: "Show more files",
                    errorIconAriaLabel: "Error",
                  }}
                  showFileLastModified
                  showFileSize
                  showFileThumbnail
                  tokenLimit={1}
                  constraintText=".jpg, .jpeg, or .png file"
                />
              </FormField>
              <FormField label="Company Description">
                <Textarea
                  value={companyDescriptionInForm}
                  placeholder="e.g. Company Blurb"
                  onChange={({ detail }) => setCompanyDescriptionInForm(detail.value)}
                />
              </FormField>
            </SpaceBetween>

          </SpaceBetween>
        </Form>
      </Modal>

      {/* edit company modal */}
      <Modal
        visible={isEditCompanyModelOpen}
        onDismiss={() => {
          setIsEditCompanyModelOpen(false)
          setCompaniesToEdit([])
        }}
      >
        <Form
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button onClick={() => {
                setIsEditCompanyModelOpen(false)
                setCompaniesToEdit([])
              }}>
                Cancel
              </Button>
              <Button
                onClick={handleEditFormSubmit}
                variant="primary"
                formAction="submit"
                disabled={!companyNameInForm.trim()}
              >
                Submit
              </Button>
            </SpaceBetween>
          }>
          <SpaceBetween direction="vertical" size="l">
            <SpaceBetween direction="vertical" size="l">
              <FormField label="Company Name">
                <Input
                  value={companyNameInForm}
                  onChange={({ detail }) => setCompanyNameInForm(detail.value)}
                />
              </FormField>
              <FormField label="Company Type">
                <Input
                  value={companyTypeInForm}
                  onChange={({ detail }) => setCompanyTypeInForm(detail.value)}
                />
              </FormField>
              <FormField label="Company Logo">
                <FileUpload
                  accept="image/jpeg, image/jpg, image/png"
                  value={companyLogoInForm as File[]}
                  onChange={({ detail }) => setCompanyLogoInForm(detail.value)}
                  i18nStrings={{
                    uploadButtonText: (e) => (e ? "Choose files" : "Choose file"),
                    dropzoneText: (e) =>
                      e ? "Drop files to upload" : "Drop file to upload",
                    removeFileAriaLabel: (e) => `Remove file ${e + 1}`,
                    limitShowFewer: "Show fewer files",
                    limitShowMore: "Show more files",
                    errorIconAriaLabel: "Error",
                  }}
                  showFileLastModified
                  showFileSize
                  showFileThumbnail
                  tokenLimit={1}
                  constraintText=".jpg, .jpeg, or .png file"
                />
              </FormField>
              <FormField label="Company Description">
                <Textarea
                  value={companyDescriptionInForm}
                  onChange={({ detail }) => setCompanyDescriptionInForm(detail.value)}
                />
              </FormField>
            </SpaceBetween>

          </SpaceBetween>
        </Form>
      </Modal>

      <Modal
        onDismiss={() => setCompanyToDelete(null)}
        visible={!!companyToDelete?.id}
        header={`Remove ${companyToDelete?.name}`}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link" onClick={() => setCompanyToDelete(null)}>
                No
              </Button>
              <Button
                // loading={isDeleting}
                loadingText="Removing Company..."
                variant="primary"
                onClick={deleteCompanyHandler}
              >
                Yes
              </Button>
            </SpaceBetween>
          </Box>
        }
      >
        You are about to remove {companyToDelete?.name}. Are you sure?
      </Modal>

    </>
  );
};
