import { useEffect, useState } from "react";
import { generateClient } from "aws-amplify/api";
import {
  Box,
  Button,
  Checkbox,
  Container,
  Form,
  FormField,
  Grid,
  Header,
  Icon,
  Input,
  Modal,
  Select,
  SpaceBetween,
  Table,
} from "@cloudscape-design/components";
import * as Sentry from "@sentry/browser";

import { useUserContext } from "../contexts/UserContext";

import {
  createForm as createFormMutation,
  deleteForm as deleteFormMutation,
  updateForm as updateFormMutation,
} from "../graphql/mutations";
import { formsByCompanyId as formsByCompanyIdQuery } from "../graphql/queries";
import {
  DEFAULT_FORM_INPUT_TYPES,
  DEFAULT_REPORT_LABELS,
} from "../utils/constants";
import { Form as FormType } from "../API";
import { tFormInput, tFormPrompts, tPrompt, tQuestionContent, tSectionContent } from "../types";
import { useCompanyContext } from "../contexts/CompanyContext";

const client = generateClient({ authMode: "userPool" });



export const FormsContent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isEditing, setIsEditing] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false);
  const [formId, setFormId] = useState<string | null>(null);
  const [isFormModalOpen, setIsFormModelOpen] = useState(false)
  const [formName, setFormName] = useState("");
  const [formContents, setFormContents] = useState<tFormPrompts[]>([]);
  const [noMachineHours, setNoMachineHours] = useState(false);
  const [formsList, setFormsList] = useState<FormType[]>([]);
  const [selectedForm, setSelectedForm] = useState<FormType | null>(null);
  const { company } = useUserContext();
  const { company: companyContext } = useCompanyContext();

  const companyId = company?.id as string || companyContext?.id as string

  const getFormsList = async () => {
    if (!company && !companyContext) {
      return;
    } else {
      setIsLoading(true);
      try {
        const result = await client.graphql({
          query: formsByCompanyIdQuery,
          variables: {
            companyId: companyId,
          },
        });
        setFormsList(result.data.formsByCompanyId.items);
      } catch (error) {
        console.log(error);
        Sentry.captureException(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    getFormsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderQuestion = (content: tFormPrompts, index: number) => {
    return (
      <Grid
        gridDefinition={[
          { colspan: { default: 8, s: 8 } },
          { colspan: { default: 3, s: 3 } },
          { colspan: { default: 1, s: 1 } },
        ]}
      >
        <FormField label="Prompt" stretch>
          <Input
            autoComplete={false}
            onChange={({ detail }) => {
              setFormContents([
                ...formContents.slice(0, index),
                {
                  type: DEFAULT_FORM_INPUT_TYPES.question,
                  prompt: detail.value,
                },
                ...formContents.slice(index + 1),
              ]);
            }}
            value={content.prompt || ""}
            placeholder="e.g. General Condition"
          />
        </FormField>
        <FormField label="Type Of Input" stretch>
          <Select
            selectedOption={
              content.inputMode || { label: "Text", value: "text" }
            }
            onChange={({ detail }) => {
              setFormContents([
                ...formContents.slice(0, index),
                {
                  ...formContents[index],
                  inputMode: detail.selectedOption,
                },
                ...formContents.slice(index + 1),
              ] as tFormPrompts[]);
            }}
            options={[
              { label: "Text", value: "text" },
              { label: "Number", value: "decimal" },
              { label: "Large Text", value: "textarea" },
            ]}
          />
        </FormField>
        <Box margin={{ top: "xl" }}>
          <Button
            formAction="none"
            variant="icon"
            iconName="remove"
            onClick={() => {
              setFormContents([
                ...formContents.slice(0, index),
                ...formContents.slice(index + 1),
              ]);
            }}
          />
        </Box>
      </Grid>
    );
  };

  const renderSection = (
    content: {
      prompt?: string | undefined;
      type?: string;
      name: string;
      inputMode?: { label: string; value: string } | undefined;
      prompts: tPrompt[];
    },
    index: number
  ) => {
    const addPromptHandler = () => {
      setFormContents([
        ...formContents.slice(0, index),
        {
          type: DEFAULT_FORM_INPUT_TYPES.section,
          name: content.name,
          prompts: [
            ...content.prompts,
            {
              type: DEFAULT_FORM_INPUT_TYPES.question,
              component: "",
              description: "",
            },
          ],
        },
        ...formContents.slice(index + 1),
      ]);
    };

    return (
      <Container>
        <FormField label="Section Name" stretch>
          <SpaceBetween direction="vertical" size="xl">
            <Input
              autoComplete={false}
              placeholder="e.g. Engine Compartment"
              value={content.name || ""}
              onChange={({ detail }) => {
                setFormContents([
                  ...formContents.slice(0, index),
                  {
                    type: DEFAULT_FORM_INPUT_TYPES.section,
                    name: detail.value,
                    prompts: content.prompts,
                  },
                  ...formContents.slice(index + 1),
                ]);
              }}
            />
            {content.prompts.map(
              (
                prompt: { component: string; description: string },
                idx: number
              ) => {
                return (
                  <Grid
                    key={idx}
                    gridDefinition={[
                      { colspan: 4 },
                      { colspan: 7 },
                      { colspan: 1 },
                    ]}
                  >
                    <FormField label="What are you inspecting?" stretch>
                      <Input
                        autoComplete={false}
                        type="text"
                        placeholder="e.g. Lights"
                        value={prompt.component}
                        onChange={({ detail }) => {
                          setFormContents([
                            ...formContents.slice(0, index),
                            {
                              type: DEFAULT_FORM_INPUT_TYPES.section,
                              name: content.name,
                              prompts: [
                                ...content.prompts.slice(0, idx),
                                {
                                  ...prompt,
                                  component: detail.value,
                                },
                                ...content.prompts.slice(idx + 1),
                              ],
                            },
                            ...formContents.slice(index + 1),
                          ]);
                        }}
                      />
                    </FormField>
                    <FormField label="What are you looking for?" stretch>
                      <Input
                        autoComplete={false}
                        type="text"
                        placeholder="e.g. Broken lamps, lenses, operation"
                        value={prompt.description}
                        onChange={({ detail }) => {
                          setFormContents([
                            ...formContents.slice(0, index),
                            {
                              type: DEFAULT_FORM_INPUT_TYPES.section,
                              name: content.name,
                              prompts: [
                                ...content.prompts.slice(0, idx),
                                {
                                  ...prompt,
                                  description: detail.value,
                                },
                                ...content.prompts.slice(idx + 1),
                              ],
                            },
                            ...formContents.slice(index + 1),
                          ]);
                        }}
                      />
                    </FormField>
                    <Box margin={{ top: "xl" }}>
                      <Button
                        formAction="none"
                        variant="icon"
                        iconName="remove"
                        onClick={() => {
                          setFormContents([
                            ...formContents.slice(0, index),
                            {
                              type: DEFAULT_FORM_INPUT_TYPES.section,
                              name: content.name,
                              prompts: [
                                ...content.prompts.slice(0, idx),
                                ...content.prompts.slice(idx + 1),
                              ],
                            },
                            ...formContents.slice(index + 1),
                          ]);
                        }}
                      />
                    </Box>
                  </Grid>
                );
              }
            )}
            <SpaceBetween direction="horizontal" size="xl">
              <Button
                formAction="none"
                variant="normal"
                onClick={addPromptHandler}
              >
                <Icon name="add-plus" /> Add A Prompt
              </Button>
              <Button
                formAction="none"
                variant="normal"
                onClick={() => {
                  setFormContents([
                    ...formContents.slice(0, index),
                    ...formContents.slice(index + 1),
                  ]);
                }}
              >
                <Icon name="remove" /> Remove This Section
              </Button>
            </SpaceBetween>
          </SpaceBetween>
        </FormField>
      </Container>
    );
  };

  const renderElement = (
    content: tQuestionContent | tSectionContent,
    index: number
  ) => {
    switch (content.type) {
      case DEFAULT_FORM_INPUT_TYPES.question:
        return renderQuestion(content as tQuestionContent, index);
      case DEFAULT_FORM_INPUT_TYPES.section:
        return renderSection(content as tSectionContent, index);
    }
  };

  const formSubmitHandler = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (!company && !companyContext) {
      return;
    }
    setIsCreating(true);
    const input: tFormInput = {
      name: formName,
      formData: JSON.stringify(formContents),
      noMachineHours,
      companyId: companyId,
    };
    if (formId) {
      input.id = formId;
    }
    try {
      await client.graphql({
        query: formId ? updateFormMutation : createFormMutation,
        variables: {
          input,
        },
      });
      getFormsList();
      setFormContents([]);
      setFormName("");
      setFormId(null);
      setIsFormModelOpen(false)
      setNoMachineHours(false)
      setIsEditing(false)
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    } finally {
      setIsCreating(false);
    }
  };

  return (
    <>

      <Table
        variant="embedded"
        header={
          <Header
            variant="h2"
            counter={`(${formsList.length})`}
            actions={
              <Button onClick={() => setIsFormModelOpen(true)}>
                Create a Form
              </Button>
            }
          >
            Forms
          </Header>
        }
        columnDefinitions={[
          {
            id: "name",
            header: "Name",
            cell: (item) => item.name,
          },
          {
            id: "edit",
            header: "Edit",
            cell: (item) => {
              return (
                <Button
                  formAction="none"
                  variant="icon"
                  iconName="edit"
                  onClick={() => {
                    setIsEditing(true)
                    setIsFormModelOpen(true)
                    setNoMachineHours(item.noMachineHours as boolean)
                    setFormContents(JSON.parse(item.formData));
                    setFormName(item.name);
                    setFormId(item.id);
                  }}
                />
              );
            },
          },
          {
            id: "remove",
            header: "Delete",
            cell: (item) => {
              return (
                <Button
                  formAction="none"
                  variant="icon"
                  iconName="remove"
                  onClick={() => setSelectedForm(item)}
                />
              );
            },
          },
        ]}
        loading={isLoading}
        loadingText="Loading Forms..."
        items={formsList}
        empty={
          <Box textAlign="center" color="inherit">
            <b>No forms</b>
          </Box>
        }
      />


      {/* Modal to Add or Edit a Form */}
      <Modal
        size="max"
        visible={isFormModalOpen}
        onDismiss={() => {
          setIsFormModelOpen(false),
            setIsEditing(false)
        }}
      >
        <SpaceBetween direction="vertical" size="xl">
          <Box margin={{ bottom: "xxl" }}>
            <Box margin={{ bottom: "xxl" }}>
              <Header variant="h1">{isEditing ? "Edit Form Mode" : "Create a Form"}</Header>
            </Box>
            <form onSubmit={formSubmitHandler}>
              <Form
                actions={
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button
                      formAction="none"
                      variant="normal"
                      onClick={() => {
                        setIsFormModelOpen(false)
                        setIsEditing(false)
                        setFormContents([]);
                        setFormName("");
                        setFormId(null);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      loading={isCreating}
                      variant="primary"
                      disabled={
                        !formName ||
                        formContents?.length === 0 ||
                        formContents.some(
                          (content) => content?.prompt?.length === 0
                        )
                      }
                    >
                      Submit
                    </Button>
                  </SpaceBetween>
                }
              >
                <SpaceBetween direction="vertical" size="l">
                  <FormField
                    label="Form Name"
                    stretch
                    description={`Enter a form name.  Then add form fields below.${noMachineHours
                      ? ""
                      : `  '${DEFAULT_REPORT_LABELS.machineHours}' will be asked first.  You do not need to add a prompt for it.`
                      }`}
                  >
                    <Input
                      autoComplete={false}
                      onChange={({ detail }) => {
                        setFormName(detail.value);
                      }}
                      value={formName}
                    />
                  </FormField>
                  <FormField
                    label={`'${DEFAULT_REPORT_LABELS.machineHours}' do not apply to this equipment`}
                    stretch
                  >
                    <Checkbox
                      onChange={({ detail }) => {
                        setNoMachineHours(detail.checked);
                      }}
                      checked={noMachineHours}
                    />
                  </FormField>
                  {formContents.map((content, index) => {
                    return (
                      <SpaceBetween direction="vertical" size="l" key={index}>
                        {renderElement(content, index)}
                      </SpaceBetween>
                    );
                  })}
                  <SpaceBetween
                    direction="horizontal"
                    size="xs"
                    alignItems="center"
                  >
                    <Button
                      formAction="none"
                      onClick={() => {
                        setFormContents([
                          ...formContents,
                          {
                            type: DEFAULT_FORM_INPUT_TYPES.question,
                            prompt: "",
                          },
                        ]);
                      }}
                    >
                      <Icon name="add-plus" /> Add A Prompt
                    </Button>
                    <Button
                      formAction="none"
                      onClick={() => {
                        setFormContents([
                          ...formContents,
                          {
                            type: DEFAULT_FORM_INPUT_TYPES.section,
                            name: "",
                            prompts: [],
                          },
                        ]);
                      }}
                    >
                      <Icon name="add-plus" /> Add A Section
                    </Button>
                  </SpaceBetween>
                </SpaceBetween>
              </Form>
            </form>
          </Box>
        </SpaceBetween>
      </Modal>



      {/* Modal For Deleting a Form */}
      <Modal
        onDismiss={() => setSelectedForm(null)}
        visible={!!selectedForm?.id}
        header={`Remove ${selectedForm?.name}`}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link" onClick={() => setSelectedForm(null)}>
                No
              </Button>
              <Button
                loading={isDeleting}
                loadingText="Removing Form..."
                variant="primary"
                onClick={async () => {
                  if (!selectedForm?.id) {
                    return;
                  }
                  try {
                    await client.graphql({
                      query: deleteFormMutation,
                      variables: {
                        input: {
                          id: selectedForm.id,
                        },
                      },
                    });
                    getFormsList();
                    setSelectedForm(null);
                  } catch (error) {
                    console.log(error);
                    Sentry.captureException(error);
                  } finally {
                    setIsDeleting(false);
                  }
                }}
              >
                Yes
              </Button>
            </SpaceBetween>
          </Box>
        }
      >
        You are about to delete the {`"${selectedForm?.name}"`} form. Are you
        sure?
      </Modal>
    </>
  );
};
