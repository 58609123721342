/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-1",
    "aws_appsync_graphqlEndpoint": "https://l7ao6g5xizaclltzzoinhsthne.appsync-api.us-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-sduiu5aqxbcdpjfd2om4ool5rq",
    "aws_cognito_identity_pool_id": "us-west-1:284568e5-ee96-4079-86bb-b475be871758",
    "aws_cognito_region": "us-west-1",
    "aws_user_pools_id": "us-west-1_uzat5qPSM",
    "aws_user_pools_web_client_id": "2o2gs0h9cditq91pmrbv53vs8r",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "equipmentinspection21086e2b52854ebda20cd86fd7ec211200-main",
    "aws_user_files_s3_bucket_region": "us-west-1"
};


export default awsmobile;
