import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { generateClient } from "aws-amplify/api";
import {
  Button,
  Header,
  Select,
  SpaceBetween,
  Table,
} from "@cloudscape-design/components";

import { useUserContext } from "../contexts/UserContext";

import * as Sentry from "@sentry/browser";

import {
  reportsByCompanyId as reportsByCompanyIdQuery,
  reportsByEquipmentId as reportsByEquipmentIdQuery,
  equipmentByCompanyId as equipmentByCompanyIdQuery,
} from "../graphql/queries";
import { onCreateReport } from "../graphql/subscriptions";
import { Equipment, ModelSortDirection, Report, Unit } from "../API";

import { ALL_EQUIPMENT, LOCAL_STORAGE_KEYS } from "../utils/constants";
import { tSelectLabelValue } from "../types";
import { useCompanyContext } from "../contexts/CompanyContext";

const client = generateClient({ authMode: "userPool" });

export const ReportsContent = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [reportsList, setReportsList] = useState<Report[] | []>([]);
  const [selectedEquipment, setSelectedEquipment] = useState<tSelectLabelValue>(ALL_EQUIPMENT);
  const [selectedUnit, setSelectedUnit] = useState<tSelectLabelValue | null>(null);
  const [equipmentById, setEquipmentById] = useState<Record<string, Equipment | null>>({});
  const navigate = useNavigate();

  const { company } = useUserContext();
  const { company: companyContext } = useCompanyContext();

  const companyId = company?.id as string || companyContext?.id as string

  const [equipmentList, setEquipmentList] = useState<Equipment[] | []>([]);
  // console.log('company Id', companyId)

  const getReportsListByDate = async () => {
    if (!company?.id && !companyContext?.id) {
      return
    }
    setIsLoading(true);
    try {
      const result = await client.graphql({
        query: reportsByCompanyIdQuery,
        variables: {
          companyId: companyId,
          sortDirection: "DESC" as ModelSortDirection,
        },
      });
      setReportsList(result.data.reportsByCompanyId.items);
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCompanyEquipment = async () => {
    if (!company?.id && !companyContext?.id) {
      return
    }
    setIsLoading(true);
    try {
      const equipmentResult = await client.graphql({
        query: equipmentByCompanyIdQuery,
        variables: {
          companyId: companyId,
        },
      });
      setEquipmentList(equipmentResult.data.equipmentByCompanyId.items);
      const equipObj: Record<string, Equipment> = {};
      equipmentResult.data.equipmentByCompanyId.items.forEach(
        (item) => (equipObj[item.id] = { ...item })
      );
      setEquipmentById(equipObj);
    } catch (error) {
      console.error("Error fetching data:", error);
      Sentry.captureException(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getReportsListByDate();
    fetchCompanyEquipment();
    localStorage.removeItem(LOCAL_STORAGE_KEYS.REPORT);

    const updateSub = client
      .graphql({
        query: onCreateReport,
        variables: {
          filter: {
            companyId: { eq: companyId },
          },
        },
      })
      .subscribe({
        next: ({ data }) => {
          setReportsList((reportsInState) => {
            return selectedEquipment.value === ALL_EQUIPMENT.value ||
              data.onCreateReport.equipmentId === selectedEquipment.value
              ? [data.onCreateReport, ...reportsInState]
              : reportsInState;
          });
        },
      });
    return () => {
      updateSub.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getReportsList = async () => {
      setIsLoading(true);
      try {
        if (selectedEquipment.value === ALL_EQUIPMENT.value) {
          const result = await client.graphql({
            query: reportsByCompanyIdQuery,
            variables: {
              companyId: companyId || '',
              sortDirection: "DESC" as ModelSortDirection,
            },
          })
          setReportsList(
            result.data.reportsByCompanyId?.items
          );
        } else {
          const result = await client.graphql({
            query: reportsByEquipmentIdQuery,
            variables: {
              equipmentId: selectedEquipment.value,
              sortDirection: "DESC" as ModelSortDirection,
            },
          });
          setReportsList(
            result.data.reportsByEquipmentId?.items
          );
        }
      } catch (error) {
        console.log(error);
        Sentry.captureException(error);
      } finally {
        setIsLoading(false);
      }
    };
    if (selectedEquipment) {
      getReportsList();
    }
  }, [company?.id, companyContext?.id, selectedEquipment]);

  const hasUnitsInEquipment = (equipment: tSelectLabelValue): (Unit | null)[] => {
    const equipmenWithUnits = equipmentList.find(
      (item) => item.id === equipment?.value
    );
    return (
      (equipmenWithUnits?.equipment?.length && equipmenWithUnits?.equipment?.length > 0 && equipmenWithUnits.equipment) || []
    );
  };

  return (
    <SpaceBetween direction="vertical" size="xl">
      <>
        <Header variant="h1" counter={`(${selectedUnit && selectedUnit.value !== ''
          ? reportsList.filter((item) => item.unitId === selectedUnit.value).length
          : reportsList.length})`}>Reports</Header>
        <Select
          onChange={({ detail }) => {
            setReportsList([]);
            setSelectedEquipment(detail.selectedOption as tSelectLabelValue);
            setSelectedUnit(null);
          }}
          options={[
            ALL_EQUIPMENT,
            ...equipmentList.map((equipment) => ({
              label: equipment.name,
              value: equipment.id,
            })),
          ]}
          selectedOption={selectedEquipment}
        />
        <Select
          onChange={({ detail }) => {
            setSelectedUnit(detail.selectedOption as tSelectLabelValue);
          }}
          options={
            hasUnitsInEquipment(selectedEquipment).length
              ? [
                { label: `All Units`, value: '' },
                ...hasUnitsInEquipment(selectedEquipment).map((unit) => ({
                  label: unit?.name,
                  value: unit?.id,
                })),
              ]
              : []
          }
          placeholder="Select a unit"
          empty="No units"
          selectedOption={selectedUnit}
        />
        <Table
          variant="embedded"
          columnDefinitions={[
            {
              id: "equipment",
              header: "Equipment",
              cell: (item) => equipmentById[item.equipmentId]?.name,
            },
            {
              id: "unit",
              header: "Unit",
              cell: (item) => {
                if (item.unitId) {
                  return `${item.unitName}`;
                }
                return "--";
              }
            },
            {
              id: "date",
              header: "Date",
              cell: (item) => (<Button
                onClick={() => {
                  localStorage.setItem(
                    LOCAL_STORAGE_KEYS.REPORT,
                    JSON.stringify({
                      [item.id]: {
                        ...item,
                        equipmentName: equipmentById[item.equipmentId]?.name,
                        lastServiceHours: item?.unitId
                          ? equipmentById[item.equipmentId]?.equipment?.find(
                            (unit) => unit?.id === item.unitId
                          )?.lastServiceHours || 0
                          : equipmentById[item.equipmentId]
                            ?.lastServiceHours || 0,
                      },
                    })
                  );
                  navigate(`/report/${item.id}`);
                }}
                variant="inline-link"
              >{new Date(item.createdAt).toLocaleString()}</Button>),
            },
          ]}
          columnDisplay={[{ id: 'equipment', visible: true }, {
            id: 'unit', visible: selectedUnit && selectedUnit.value !== ''
              ? reportsList.filter((item) => item.unitId === selectedUnit.value).some((item) => item.unitId)
              : reportsList.some((item) => item.unitId)
          }, { id: 'date', visible: true }]}
          items={
            selectedUnit && selectedUnit.value !== ''
              ? reportsList.filter((item) => item.unitId === selectedUnit.value)
              : reportsList
          }
          loading={isLoading}
          empty={
            <div>
              <h3>No reports found</h3>
              <p>Try selecting a different unit or equipment</p>
            </div>
          }
        />
      </>
    </SpaceBetween>
  );
};
