import {
  Button,
  Container,
  Grid,
  Header,
  Link,
  SpaceBetween,
} from "@cloudscape-design/components";
import PrintIcon from "@mui/icons-material/Print";
import { QRCodeSVG } from "qrcode.react";
import { useNavigate } from "react-router-dom";
import { NETWORK_DOMAIN } from "../utils/constants";
import { Flex, Image } from "@aws-amplify/ui-react";
import { Equipment } from "../API";

export const QRCodes = () => {
  const navigate = useNavigate();
  const equipmentList = JSON.parse(sessionStorage.getItem("equipmentList") || "") as Equipment[];

  return (
    <>
      <div className="hide-from-printer">
        <SpaceBetween direction="horizontal" size="xxl">
          <Link
            onFollow={(e) => {
              e.preventDefault();
              sessionStorage.removeItem("equipmentList");
              navigate("/equipment");
            }}
          >
            Back
          </Link>
          <Button variant="inline-link" onClick={() => window.print()}>
            <PrintIcon />
          </Button>
        </SpaceBetween>
      </div>
      <Grid >
        {equipmentList.map((equipment) => {
          return (
            <Container
              key={equipment.id}
              header={
                <Flex direction="row" justifyContent="space-between">
                  <Header variant="h2">{equipment.name}</Header>
                  <Image src="ei_logo.jpg" alt="logo" height={50} width={50} />
                </Flex>
              }
            >
              <>
                <a href={`${NETWORK_DOMAIN}/inspect/${equipment.id}`} target="_blank"><QRCodeSVG
                  bgColor={"#ffffff"}
                  fgColor={"#000000"}
                  includeMargin
                  level={"H"}
                  size={200}
                  value={`${NETWORK_DOMAIN}/inspect/${equipment.id}`}
                /></a>
                <div className="hidden">
                  QR link: {`${NETWORK_DOMAIN}/inspect/${equipment.id}`}
                </div>
              </>
            </Container>
          );
        })}
      </Grid>
    </>
  );
};
