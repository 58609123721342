import {
    Header,

} from "@cloudscape-design/components";


export const UpgradeSubscription = () => {

    return (
        <>
            <Header variant="h1" id="page-top">Coming Soon!!</Header>

        </>
    );
};
