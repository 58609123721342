import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { generateClient } from "aws-amplify/api";
import { getCurrentUser, fetchUserAttributes, AuthUser } from "aws-amplify/auth";
import { Authenticator, useAuthenticator, View } from "@aws-amplify/ui-react";
import { Box } from "@cloudscape-design/components";
import * as Sentry from "@sentry/browser";
import { SignInHeader } from "../components/Auth/SignInHeader/SignInHeader";
import { SignInFooter } from "../components/Auth/SignInFooter/SignInFooter";
import { SignUpHeader } from "../components/Auth/SignUpHeader/SignUpHeader";
import { LoadingSpinner } from "../components/LoadingSpinner";
import { NotFound } from "./NotFound";
import { updateUser } from "../graphql/mutations";
import { getUser as getUserQuery } from "../graphql/queries";
import { useUserContext } from "../contexts/UserContext";
import { ROLES } from "../utils/constants";
import { User } from "../API";

const client = generateClient({ authMode: "apiKey" });

export const Register = () => {
  const { userIdFromParams } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [userNotFound, setUserNotFound] = useState(false);
  const [userFromId, setUserFromId] = useState<User | null>(null);
  const { user, route } = useAuthenticator((context) => [
    context.user,
    context.route,
  ]);
  const { getUserDetails } = useUserContext();

  const navigate = useNavigate();
  const { toForgotPassword } = useAuthenticator();

  useEffect(() => {
    const getUserFromId = async () => {
      try {
        const result = await client.graphql({
          query: getUserQuery,
          variables: { id: userIdFromParams as string },
          authMode: "apiKey",
        });
        setIsLoading(false);
        if (!result.data.getUser || result.data.getUser.cognitoId) {
          return setUserNotFound(true);
        }
        setUserFromId(result.data.getUser as User);
      } catch (error) {
        console.log("error", error);
        Sentry.captureException(error);
      }
    };
    if (!userFromId && userIdFromParams) {
      getUserFromId();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const updateUserWithCognitoId = async (currentUser: AuthUser) => {
      try {
        const attributes = await fetchUserAttributes();
        if (
          !userFromId?.cognitoId &&
          currentUser.username === userFromId?.email && userIdFromParams
        ) {
          const input = {
            id: userIdFromParams,
            cognitoId: currentUser.userId,
            name: attributes.name,
          };
          await client.graphql({
            query: updateUser,
            variables: { input },
            authMode: "userPool",
          });
          getUserDetails(); // Before redirecting to the appropriate page, load the user's details into state.
          navigate(
            "/dashboard",
            {
              replace: true,
            }
          );
          return;
        }
        if (userFromId?.cognitoId !== currentUser.userId) {
          return setUserNotFound(true);
        }
      } catch (error) {
        console.log("error fetching user attributes", error);
        Sentry.captureException(error);
      }
    };
    const getNewUser = async () => {
      const newUser = await getCurrentUser();
      updateUserWithCognitoId(newUser);
    };
    if (route === "authenticated") {
      getNewUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route, userFromId, user]);

  const formFields = {
    signUp: {
      name: {
        placeholder: "Enter Your Name Here",
        isRequired: true,
        label: "Name:",
      },
    },
  };

  const components = {
    SignIn: {
      Header() {
        return <SignInHeader />;
      },
      Footer() {
        return (
          <SignInFooter
            toForgotPassword={toForgotPassword}
            forgotPasswordText="Forgot Password"
          />
        );
      },
    },
    SignUp: {
      Header() {
        return <SignUpHeader />;
      },
    },
  };

  return (
    <LoadingSpinner
      content={
        userNotFound ? (
          <NotFound />
        ) : (
          <View className="auth-wrapper centered-wrapper">
            <Box textAlign="center">
              <img
                className="centered-logo"
                src="../ei_logo.jpg"
                alt="logo"
                width="300"
              />
            </Box>
            <Authenticator
              initialState="signUp"
              formFields={formFields}
              components={components}
              loginMechanisms={["email"]}
            ></Authenticator>
          </View>
        )
      }
      isLoading={isLoading}
    />
  );
};
