import { Box, Header } from "@cloudscape-design/components";
import { CompanyAdmin } from "../components/CompanyAdmin";

export const Admin = (): JSX.Element => {
  return (
    <Box>
      <Header variant="h1">Company Administration</Header>
      <CompanyAdmin />
    </Box>
  );
};
