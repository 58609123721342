import { useCallback } from "react";
import { To, useNavigate } from "react-router";

export default function useLink() {
  const navigate = useNavigate();

  return {
    handleFollow: useCallback(
      (e: { preventDefault: () => void; }, href: To) => {
        e.preventDefault();
        navigate(href);
      },
      [navigate]
    ),
  };
}
