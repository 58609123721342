import { Spinner } from "@cloudscape-design/components";
import { Flex } from "@aws-amplify/ui-react";
import { iLoadingSpinnerProps } from "../types";

export const LoadingSpinner = ({ isLoading, content }: iLoadingSpinnerProps) => {
  return isLoading ? (
    <Flex justifyContent="center" alignItems="center">
      <Spinner size="large" />
    </Flex>
  ) : (
    <>{content}</>
  );
};
