import { Box } from "@cloudscape-design/components";

export const SignUpHeader = () => {
  return (
    <>
      <Box variant="h2" margin={{ left: "xxl" }}>
        Create an account
      </Box>
      <Box variant="p" margin={{ left: "xxl" }}>
        If you already have an account, please return home to log in.
      </Box>
    </>
  );
};
