import {
  Button,
  Header,
  SpaceBetween,
  Table,
} from "@cloudscape-design/components";
import { DisplayStatusIcon } from "./DisplayStatusIcon";
import { DEFAULT_FORM_INPUT_TYPES } from "../utils/constants";
import { iReviewReportProps } from "../types";

export const ReviewReport = ({ cancel, inspectionFormPrompts, media, submit }: iReviewReportProps) => {
  return (
    <Table
      header={
        <Header
          actions={
            <SpaceBetween direction="horizontal" size="l">
              <Button onClick={cancel}>Cancel</Button>
              <Button onClick={submit} variant="primary">
                Submit
              </Button>
            </SpaceBetween>
          }
        />
      }
      columnDefinitions={[
        {
          id: "prompt",
          header: "Prompt",
          cell: (item) =>
            item.type === DEFAULT_FORM_INPUT_TYPES.section
              ? `${item.title} / ${item.label}`
              : item.label,
        },
        {
          id: "value",
          header: "Value",
          cell: (item) => (
            <DisplayStatusIcon size="big">{item.value}</DisplayStatusIcon>
          ),
        },
        {
          id: "notes",
          header: "Notes",
          cell: (item) => item.notes,
        },
        {
          id: "media",
          header: "Media Files",
          cell: (item) => item.photo,
        },
      ]}
      items={inspectionFormPrompts.map((prompts, idx) => {
        return {
          ...prompts,
          photo: media && media[idx]?.length || 0,
        };
      })}
      wrapLines
    />
  );
};
