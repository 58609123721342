import { BrowserRouter as Router } from "react-router-dom";
import { AppLayout } from "@cloudscape-design/components";
import { UserProvider } from "./contexts/UserContext";
import { CompanyProvider } from "./contexts/CompanyContext";
import { AppRoutes } from "./AppRoutes";
import { Navbar } from "./components/Navbar";
import { Footer } from "./components/Footer/Footer/Footer";

import "./App.css";

export const App = () => {
  return (
    <Router>
      <UserProvider>
        <CompanyProvider>
          <Navbar />
          <AppLayout
            ariaLabels={{
              navigation: "Navigation drawer",
              navigationClose: "Close navigation drawer",
              navigationToggle: "Open navigation drawer",
              notifications: "Notifications",
              tools: "Help panel",
              toolsClose: "Close help panel",
              toolsToggle: "Open help panel",
            }}
            content={<AppRoutes />}
            footerSelector="app-footer"
            headerSelector="awsui-context-top-navigation"
            maxContentWidth={Number.MAX_VALUE}
            navigationHide
            toolsHide
          />
          <Footer />
        </CompanyProvider>
      </UserProvider>
    </Router>
  );
};
