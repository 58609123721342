import {
  Box,
  Header,
  Link,
  SpaceBetween,
} from "@cloudscape-design/components";
import { Card, Collection, Heading, View } from "@aws-amplify/ui-react";
import { useEffect, useState } from "react";
import { generateClient } from "aws-amplify/api";
import * as Sentry from "@sentry/browser";
import { useUserContext } from "../contexts/UserContext";
import {
  PERMISSION_VALUES,
} from "../utils/constants";

import { DashboardContentProps } from "../types";
import { equipmentByCompanyId as equipmentByCompanyIdQuery } from "../graphql/queries";
import { Equipment } from "../API";
import { CompanyInfo } from "./CompanyInfo";
import { userHasExplicitPermission } from "../utils/helperFunctions";
import "../css/DashboardContentUsers.css"

const client = generateClient();

export const DashboardContent = ({ companyUser }: DashboardContentProps) => {
  const [equipment, setEquipment] = useState<Equipment[]>([]);

  const { company } = useUserContext();

  const getEquipment = async () => {
    try {
      const equipmentResponse = await client.graphql({
        query: equipmentByCompanyIdQuery,
        variables: { companyId: company?.id.toString() as string },
      });
      setEquipment(equipmentResponse.data.equipmentByCompanyId.items as Equipment[]);
    } catch (error) {
      console.log("error", error);
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    if (company !== null && company?.id) {
      getEquipment();
    }
  }, [company]);

  return (
    <SpaceBetween direction="vertical" size="m">
      <Header variant="h1" id="page-top">Welcome {companyUser?.name}!</Header>
      <CompanyInfo />
      {userHasExplicitPermission(companyUser, PERMISSION_VALUES.INSPECT) && <>
        <>
          <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
            {equipment.length > 0 && (
              <>
                <h3>Scan a QR code to start an inspection</h3>
                <h2>OR</h2>
                <h4>Select a previously inspected item from list below and click to begin a new inspection</h4>
              </>
            )}
          </Box>

          <Collection
            items={equipment}
            type="list"
            direction="row"
            justifyContent="center"
            gap="1rem"
            wrap="wrap"
            width="100%"
          >
            {(item, index) => (
              <Card
                key={index}
                borderRadius="medium"
                variation="outlined"
                height="150px"
                width="150px"
                padding="0px"
              >
                <View textAlign="center">
                  <Link href={`/inspect/${item.id}`}>
                    <Heading padding="xs">{item.name}</Heading>
                  </Link>
                  {item.image && (
                    <Link href={`/inspect/${item.id}`}>
                      <img src={item.image} alt={item.name} className="dashboard-card-image" />
                    </Link>
                  )}
                </View>
              </Card>
            )}
          </Collection>
        </>
      </>}
    </SpaceBetween>
  );
};
