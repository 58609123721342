import { useEffect } from "react";
import { Box } from "@cloudscape-design/components";
import { SignInHeader } from "../components/Auth/SignInHeader/SignInHeader";
import { SignInFooter } from "../components/Auth/SignInFooter/SignInFooter";

import { Authenticator, useAuthenticator, View } from "@aws-amplify/ui-react";
import { useNavigate, useLocation } from "react-router";
import { iLoginProps } from "../types";
import { SignUpHeader } from "../components/Auth/SignUpHeader/SignUpHeader";

export const Login = ({ logIn }: iLoginProps) => {
  const { route } = useAuthenticator((context) => [context.route]);
  const { toForgotPassword } = useAuthenticator();
  const location = useLocation();
  const navigate = useNavigate();

  const from = location.state?.from?.pathname ? `${location.state?.from?.pathname}${location.state?.from?.search}` : "/dashboard";
  useEffect(() => {
    if (route === "authenticated") {
      navigate(from, { replace: true });
    }
    // else {
    //   navigate(from, { replace: true })
    // }
  }, [route, navigate, from]);

  const components = {
    SignIn: {
      Header() {
        return <SignInHeader />;
      },
      Footer() {
        return (
          <SignInFooter
            toForgotPassword={toForgotPassword}
            forgotPasswordText="Forgot Password"
          />
        );
      },
    },
    SignUp: {
      Header() {
        console.log('header')
        return <SignUpHeader />
      },
      // handleSignUp: async (input: any, e: React.FormEvent) => {
      //   console.log("Form submitted")
      // await handleSignUpComplete(input, e);
    },
    // },
  };
  const formFields = !logIn
    ? {
      signUp: {
        name: {
          placeholder: "Enter Your Name Here",
          isRequired: true,
          label: "Name:",
        },
      },
    }
    : {
      signIn: {
        username: {
          placeholder: 'Enter Your Email Here',
          isRequired: true,
          label: 'Email:'
        },
      },
    };
  return (
    <View className="auth-wrapper centered-wrapper">
      <Box textAlign="center">
        <img
          className="centered-logo"
          src="/ei_logo.jpg"
          alt="logo"
          width="300"
        />
      </Box>
      <Authenticator
        key="signIn"
        initialState={"signIn"}
        components={components}
        formFields={formFields}
        loginMechanisms={["email"]}
        hideSignUp={logIn}
      >
        {({ signOut, user }) => (
          <div>
            <h1>Welcome {user?.username}</h1>
            <button onClick={signOut}>Sign Out</button>
          </div>
        )}
      </Authenticator>
    </View>
  );
};
