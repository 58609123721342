import {
    Box,
    Header,

} from "@cloudscape-design/components";
import { Button } from "@aws-amplify/ui-react";
import { CompanyInfo } from "./CompanyInfo";
import { useNavigate } from "react-router-dom";


export const SuperAdminDashboardContent = ({ subscriptionType }: { subscriptionType: string }) => {

    const DemoButton = () => {
        const navigate = useNavigate();
        const handleFreeDemoClick = () => {
            navigate("/register/subscription", { replace: true })
        }
        //TODO: create a register/subscription page!!
        return (
            <Button
                backgroundColor="yellow"
                loadingText=""
                onClick={handleFreeDemoClick}
            >
                Upgrade Subscription
            </Button>)
    }

    return (
        <>
            <Header variant="h1" id="page-top">Welcome Administrator!</Header>
            <CompanyInfo />
            <Box variant="h2">
                Actions
            </Box>
            <Box variant="small">
                {subscriptionType === "free-demo" ? <DemoButton /> : ""}
            </Box>
        </>
    );
};
