import { createContext, useContext, useState } from "react";
import { Company } from "../API";
import { iCompanyContext, iCompanyProvider } from "../types";

export const CompanyContext = createContext<iCompanyContext>({
  company: null,
  setCompany: () => { },
});

// eslint-disable-next-line react-refresh/only-export-components
export const useCompanyContext = () => useContext(CompanyContext);

export const CompanyProvider = ({ children }: iCompanyProvider) => {
  const [company, setCompany] = useState<Company | null>(null);

  const value = { company, setCompany };

  return (
    <CompanyContext.Provider value={value}>{children}</CompanyContext.Provider>
  );
};
