import { useEffect, useState } from "react";
import { clsx } from "clsx";
import { useParams } from "react-router-dom";
import { generateClient } from "aws-amplify/api";
import { useNetworkState } from "@uidotdev/usehooks";
import * as Sentry from "@sentry/browser";
import {
  discrepanciesByEquipmentIdAndUpdatedAt as discrepanciesByEquipmentIdQuery,
  getEquipment as getEquipmentQuery,
  reportsByEquipmentId as reportsByEquipmentIdQuery,
  reportsByUnitId as reportsByUnitIdQuery,
} from "../graphql/queries";
import { useUserContext } from "../contexts/UserContext";
import { ITEM_STATUS_CODES, LOCAL_STORAGE_KEYS } from "../utils/constants";
import { LoadingSpinner } from "../components/LoadingSpinner";
import {
  Badge,
  Box,
  Button,
  Container,
  Header,
  Select,
  SpaceBetween,
} from "@cloudscape-design/components";
import { Image } from "@aws-amplify/ui-react";
import { Inspect } from "../components/Inspect";
import { ModelSortDirection, Report, Equipment } from "../API";
import { tDiscrepancyItem } from "../types";

import '../css/InspectionOptions.css';

const client = generateClient({ authMode: "userPool" });

export const InspectionOptions = () => {
  const { id: equipmentId } = useParams();
  const network = useNetworkState();
  const { company } = useUserContext();
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [discrepancyStatus, setDiscrepancyStatus] = useState<tDiscrepancyItem[] | null>(null);
  const [equipmentData, setEquipmentData] = useState<Equipment | null>(null);
  const [lastReportData, setLastReportData] = useState<Report | null>(null);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [selectedUnitId, setSelectedUnitId] = useState<{ label: string; value: string; } | null>(null);

  useEffect(() => {
    const getEquipment = async () => {
      if (!equipmentId) {
        return;
      }
      try {
        const equipResponse =
          network.online && company?.id
            ? await client.graphql({
              query: getEquipmentQuery,
              variables: { id: equipmentId },
            })
            : JSON.parse(localStorage.getItem(`equipment_${equipmentId}`) || '{}');
        setEquipmentData(equipResponse?.data?.getEquipment);
        if (network.online && company?.id) {
          localStorage.setItem(
            `equipment_${equipmentId}`,
            JSON.stringify(equipResponse)
          );
          const discrepancyList: tDiscrepancyItem[] = [];
          const discrepanciesResponse = await client.graphql({
            query: discrepanciesByEquipmentIdQuery,
            variables: { equipmentId, limit: 100, sortDirection: "DESC" as ModelSortDirection },
          });
          discrepanciesResponse.data.discrepanciesByEquipmentIdAndUpdatedAt.items.forEach(item => {
            const newItem = {
              equipmentId: item.equipmentId,
              equipmentName: `${equipmentData?.name ? equipmentData.name : ""} ${selectedUnitId ? `- ${selectedUnitId.label}` : ""
                }`,
              unitId: item.unitId,
              status: item.status
            }
            discrepancyList.push(newItem);
          })
          setDiscrepancyStatus(discrepancyList);
        }
        if (selectedUnitId) {
          const lastReportLoggedForEquipment = (await client.graphql({
            query: reportsByUnitIdQuery,
            variables: { unitId: selectedUnitId.value, limit: 1, sortDirection: 'DESC' as ModelSortDirection },
          }));
          setLastReportData(
            lastReportLoggedForEquipment?.data?.reportsByUnitId?.items[0]
          );
        } else {
          const lastReportLoggedForEquipment = (await client.graphql({
            query: reportsByEquipmentIdQuery,
            variables: { equipmentId, limit: 1, sortDirection: "DESC" as ModelSortDirection },
          }));
          setLastReportData(
            (lastReportLoggedForEquipment?.data?.reportsByEquipmentId?.items[0]) as Report
          );
        }
      } catch (error) {
        console.log(error);
        Sentry.captureException(error);
      } finally {
        setIsPageLoading(false);
      }
    };
    if (company?.id) {
      getEquipment();
    }
  }, [company, equipmentId, selectedUnitId, network.online]);

  useEffect(() => {
    if (equipmentId) {
      localStorage.setItem(LOCAL_STORAGE_KEYS.INSPECT, equipmentId);
    }
  }, [equipmentId]);

  const isOutOfService = (): boolean => {
    if (equipmentData?.equipment?.length && !selectedUnitId) {
      return false;
    }
    return !!discrepancyStatus?.some(item => {
      return selectedUnitId ? item.equipmentId === equipmentId && item.unitId === selectedUnitId?.value && item.status === ITEM_STATUS_CODES.inop : item.equipmentId === equipmentId && item.status === ITEM_STATUS_CODES.inop;
    });
  }

  const parentClasses = clsx('inspection-options', { 'has-issue': isOutOfService() })

  return (
    <LoadingSpinner
      isLoading={isPageLoading}
      content={
        <div className={parentClasses}>
          {!selectedOption && (
            <Container
              header={
                <><Header
                  variant="h3"
                  actions={
                    <>
                      <SpaceBetween direction="horizontal" size="xl">
                        {selectedUnitId && equipmentData?.equipment?.length && (
                          <Button onClick={() => setSelectedUnitId(null)}>
                            Change Unit
                          </Button>
                        )}
                        {!(
                          equipmentData?.equipment?.length && equipmentData.equipment.length > 0 && !selectedUnitId
                        ) && (
                            <>
                              <Button
                                onClick={() => setSelectedOption("inspect")}
                                disabled={!equipmentData || !equipmentId || isOutOfService()}
                              >
                                Begin Inspection
                              </Button>
                              <Button
                                disabled={!lastReportData}
                                onClick={() => {
                                  localStorage.setItem(
                                    LOCAL_STORAGE_KEYS.REPORT,
                                    JSON.stringify({
                                      [lastReportData?.id as string]: {
                                        ...lastReportData,
                                        equipmentName: equipmentData?.name,
                                      },
                                    })
                                  );
                                  window.open(
                                    `/lastreport/${lastReportData?.id}/${equipmentId}`,
                                    "_blank",
                                    "rel=noopener noreferrer"
                                  );
                                }}
                              >
                                View Last Report
                              </Button>
                            </>
                          )}
                      </SpaceBetween>
                    </>
                  }
                ><SpaceBetween direction="horizontal" size="l">
                    {`${equipmentData?.name ? equipmentData.name : ""} ${selectedUnitId ? `- ${selectedUnitId.label}` : ""
                      }`}
                    {isOutOfService() && <Badge color="red">Out Of Service</Badge>}
                  </SpaceBetween>
                </Header></>
              }
            >
              <>
                {equipmentData?.image && <Box textAlign="center"><Image src={equipmentData?.image} alt={equipmentData?.name} /></Box>}
                {equipmentData?.equipment && equipmentData?.equipment?.length > 0 && !selectedUnitId && (
                  <Select
                    selectedOption={selectedUnitId}
                    options={equipmentData?.equipment.map((unit) => {
                      return { label: unit?.name, value: unit?.id };
                    })}
                    onChange={({ detail }) => {
                      setLastReportData(null);
                      setSelectedUnitId(detail.selectedOption as never);
                    }}
                    placeholder="Select a unit"
                  />
                )}
              </>
            </Container>
          )}
          {selectedOption === "inspect" && (
            <Inspect
              equipmentData={equipmentData as Equipment}
              equipmentId={equipmentId as string}
              selectedUnitId={selectedUnitId}
              backBtnAction={() => setSelectedOption(null)}
            />
          )}
        </div>
      }
    />
  );
};
